import { arrayOf, node, oneOf, oneOfType, shape, string } from 'prop-types'

// AnyClip video
export const ANYCLIP_SCRIPT_URL =
  'https://player.anyclip.com/anyclip-widget/lre-widget/prod/v1/src/lre.js'

// Cookie keys
export const IS_GOOGLE_SSO_USER_KEY = 'isGoogleSsoUser'
export const IS_SUBSCRIBER_KEY = '__is_subscriber__'

// Date and time formats
export const DATE_DDMMYY = 'dd MM YY'
export const DATE_ISO_8601 = 'yyyy-MM-dd'
export const DATE_MMMDDYYYY = 'MMM dd, yyyy'
export const DATE_MMMMDDYYYY = 'MMMM dd, yyyy'
export const DATETIME_DDDHHMM = 'DDD hh:mm'

// Dictionary subscription plans
export const DICTIONARY_SUBSCRIPTION_PLANS = [
  {
    code: '2024_dictionary_annual',
    isBestValue: true,
    name: 'Dictionary Annual Plan',
    price: '49.99',
    type: 'Annual'
  },
  {
    code: '2024_dictionary_monthly',
    name: 'Dictionary Monthly Plan',
    price: '4.99',
    type: 'Monthly'
  }
]

// Learning Hub
export const MINIMUM_WORDS_FOR_FLASHCARDS = 1
export const MINIMUM_WORDS_FOR_MULTIPLE_CHOICE_QUIZZES = 3
export const MINIMUM_WORDS_FOR_SPELLING_QUIZZES = 1

// Mailing list
export const MAILING_LIST_TERMS_COPY =
  'By clicking "Sign Up", you are accepting Dictionary.com'
export const MAILING_LIST_TERMS_LINK_COPY =
  'Terms & Conditions and Privacy Policies.'
export const SIGNUP_ERROR_MESSAGE =
  'Uh oh! We spotted an email error. Please re-enter!'

/**
 * Pathnames that are valid across all environments. They can be directly consumed by `useGamesUrl`,
 * `useDictionaryUrl`, `useThesaurusUrl` and exportable components, which cannot directly use state.
 */
export const PATHNAME_ACCOUNT = '/account'
export const PATHNAME_ACCOUNT_WORD_LISTS = '/account/word-lists'
export const PATHNAME_BROWSE = '/browse'
export const PATHNAME_COMPARE_SYNONYMS = '/compare-synonyms'
export const PATHNAME_COMPARE_WORDS = '/compare-words'
export const PATHNAME_COMPLETE_PROFILE = '/complete-profile'
export const PATHNAME_COMPLETE_SIGN_UP = '/complete-sign-up'
export const PATHNAME_CROSSWORD = '/e/crossword'
export const PATHNAME_EASY_CROSSWORD = '/games/easy-crossword'
export const PATHNAME_FORGOT_PASSWORD = '/forgot-password'
export const PATHNAME_GAMES_QUIZZES = '/games/quizzes'
export const PATHNAME_GAMES_WORD_PUZZLE = '/games/word-puzzle'
export const PATHNAME_LEARN_QUIZZES = '/learn/quizzes'
export const PATHNAME_LEARN_WORD_LISTS = '/learn/word-lists'
export const PATHNAME_MINI_CROSSWORD = '/games/daily-crossword-mini'
export const PATHNAME_MINI_CROSSWORD_ADS_FREE = '/games/mini-crossword'
export const PATHNAME_SIGN_IN = '/sign-in'
export const PATHNAME_SIGN_UP = '/sign-up'
export const PATHNAME_SUNDAY_CROSSWORD = '/games/sunday-crossword'
export const PATHNAME_WORD_LISTS = '/account/word-lists'

// Quiz, wordlist categories
export const QUIZ_CATEGORIES = [
  {
    categorySlug: 'commonly-confused-words',
    categoryTitle: 'Commonly Confused Words'
  },
  {
    categorySlug: 'food-drink',
    categoryTitle: 'Food &amp; Drink'
  },
  {
    categorySlug: 'fun',
    categoryTitle: 'Fun'
  },
  {
    categorySlug: 'grammar',
    categoryTitle: 'Grammar'
  },
  {
    categorySlug: 'history',
    categoryTitle: 'History'
  },
  {
    categorySlug: 'literature',
    categoryTitle: 'Literature'
  },
  {
    categorySlug: 'politics',
    categoryTitle: 'Politics'
  },
  {
    categorySlug: 'pop-culture',
    categoryTitle: 'Pop Culture'
  },
  {
    categorySlug: 'plants-animals',
    categoryTitle: 'Science &amp; Nature'
  },
  {
    categorySlug: 'synonym-of-the-day',
    categoryTitle: 'Synonym Of The Day'
  },
  {
    categorySlug: 'synonyms-antonyms',
    categoryTitle: 'Synonyms &amp; Antonyms'
  },
  {
    categorySlug: 'test-prep',
    categoryTitle: 'Test Prep'
  },
  {
    categorySlug: 'translations',
    categoryTitle: 'Translations'
  },
  {
    categorySlug: 'elementary-school-vocabulary',
    categoryTitle: 'Vocabulary'
  },
  {
    categorySlug: 'word-of-the-day',
    categoryTitle: 'Word Of The Day'
  },
  {
    categorySlug: 'writing',
    categoryTitle: 'Writing'
  }
]

export const WORD_LIST_CATEGORIES = [
  { categorySlug: 'general', categoryTitle: 'General' },
  { categorySlug: 'college', categoryTitle: 'College' },
  { categorySlug: 'common-core', categoryTitle: 'Common Core' },
  { categorySlug: 'fun', categoryTitle: 'Fun' },
  { categorySlug: 'k--12', categoryTitle: 'K-12' },
  { categorySlug: 'literature', categoryTitle: 'Literature' },
  { categorySlug: 'spelling', categoryTitle: 'Spelling' },
  { categorySlug: 'test-preparation', categoryTitle: 'Test Preparation' },
  { categorySlug: 'writing', categoryTitle: 'Writing' }
]

// Regular expressions
export const EMAIL_VALIDATION_REGEX =
  /^[\w.!#$%&’*+/=?^`{|}~-]+@([\w-]+\.)+[\w-]{2,}$/i

// Sovrn
export const SOVRN_COUNTRY_CODE_ALLOWLIST = ['US', 'CA', 'GB', 'AU', 'NZ']

// Support
export const SUPPORT_MAILING_ADDRESS = 'support@dictionary.com'

// Synonym/antonyms
export const SYNONYM_ANTONYM_WORD_STRENGTHS = [
  { label: 'Strongest', value: '100' },
  { label: 'Strong', value: '50' },
  { label: 'Weak', value: '10' }
]

// Thesaurus kebab link id values
export const ANTONYMS_ID = 'antonyms'
export const FAQ_ID = 'faq'
export const RELATED_WORDS_ID = 'related-words'
export const SYNONYMS_ID = 'synonyms'

// URLs (these URLs do not exist in lower environments) — Dictionary
export const DICTIONARY_SITENAME = 'dictionary'
export const DICTIONARY_URL = 'https://www.dictionary.com'
export const DICTIONARY_URL_CONTACT = `mailto:support@dictionary.com`
export const DICTIONARY_URL_CROSSWORD_SOLVER = `${DICTIONARY_URL}/e/crosswordsolver/`
export const DICTIONARY_URL_CROSSWORD_TRIVIA = `${DICTIONARY_URL}/e/crossword-trivia/`
export const DICTIONARY_URL_TERMS = `${DICTIONARY_URL}/e/terms/`

// URLs (these URLs do not exist in lower environments) — Games
export const PLAY_DICTIONARY_URL = 'https://play.dictionary.com'

// URLs (these URLs do not exist in lower environments) — Thesaurus
export const THESAURUS_SITENAME = 'thesaurus'
export const THESAURUS_URL = 'https://www.thesaurus.com'
export const THESAURUS_URL_SOTD = `${THESAURUS_URL}/e/synonym-of-the-day/`

// Word Puzzle
export const NUMBER_OF_WORDS_TO_WIN = 10

// Enums
export const AnyClipPubNames = {
  DICTIONARY: '2091',
  THESAURUS: '2115'
}

export const AnyClipWidgetNames = {
  DICTIONARY: {
    HOME: '0011r00002KyO15AAF_M8137',
    RESULTS: '0011r00002KyO15AAF_M8016'
  },
  THESAURUS: {
    HOME: '0011r00002KyO15AAF_M8141',
    RESULTS: '0011r00002KyO15AAF_M8144'
  }
}

export const DefinitionEntrySources = {
  AMERICAN: 'American',
  BRITISH: 'British',
  CULTURAL: 'Cultural',
  IDIOMS: 'Idioms',
  SCIENTIFIC: 'Scientific'
}

export const DefinitionSources = {
  COLLINS: 'collins',
  CULTURAL: 'cultural',
  IDIOMS: 'idioms',
  LUNA: 'luna',
  SCIENCE: 'science'
}

export const MailingLists = {
  DAILY: 'Daily',
  SOTD: 'SOTD',
  WOTD: 'WOTD',
  WRITING_INSPIRATION: 'Writing+Inspiration'
}

export const ResultsPageNavigationItems = {
  A_CLOSER_LOOK: {
    id: 'a-closer-look',
    label: 'A Closer Look'
  },
  COMPARE_MEANINGS: {
    id: 'compare-meanings',
    label: 'Compare Meanings'
  },
  CONFUSABLES_NOTE: {
    id: 'confusables-note',
    label: 'Confusables Note'
  },
  DERIVED_FORMS: {
    id: 'derived-forms',
    label: 'Derived Forms'
  },
  EXAMPLE_SENTENCES: {
    id: 'example-sentences',
    label: 'Example Sentences'
  },
  FAQ: {
    id: 'faq',
    label: 'FAQ'
  },
  GENDER_NOTE: {
    id: 'gender-note',
    label: 'Gender Note'
  },
  GRAMMAR_NOTE: {
    id: 'grammar-note',
    label: 'Grammar Note'
  },
  IDIOMS_AND_PHRASES: {
    id: 'idioms-and-phrases',
    label: 'Idioms and Phrases'
  },
  MORE_ABOUT: {
    id: 'more-about',
    label: 'More About'
  },
  NOTES: {
    id: 'notes',
    label: 'Notes'
  },
  OTHER_WORDS_FROM: {
    id: 'other-words-from',
    label: 'Other Words From'
  },
  PRONUNCIATION_NOTE: {
    id: 'pronunciation-note',
    label: 'Pronunciation Note'
  },
  QUIZ: {
    id: 'quiz',
    label: 'Quiz'
  },
  RELATED_WORDS: {
    id: 'related-words',
    label: 'Related Words'
  },
  SENSITIVE_NOTE: {
    id: 'sensitive-note',
    label: 'Sensitive Note'
  },
  SOTD: {
    id: 'synonym-of-the-day',
    label: 'Synonym of the Day'
  },
  SPELLING_NOTE: {
    id: 'spelling-note',
    label: 'Spelling Note'
  },
  SYNONYM_STUDY: {
    id: 'synonym-study',
    label: 'Synonym Study'
  },
  USAGE: {
    id: 'usage',
    label: 'Usage'
  },
  USAGE_ALERT: {
    id: 'usage-alert',
    label: 'Usage Alert'
  },
  USAGE_NOTE: {
    id: 'usage-note',
    label: 'Usage Note'
  },
  WORD_HISTORY: {
    id: 'word-history',
    label: 'Word History'
  },
  WORD_HISTORY_AND_ORIGINS: {
    id: 'word-history-and-origins',
    label: 'Word History and Origins'
  },
  WOTD: {
    id: 'word-of-the-day',
    label: 'Word of the Day'
  }
}

export const SearchResultSources = {
  DICTIONARY: 'Dictionary',
  DICTIONARY_EDITORIAL: 'Dictionary Editorial',
  THESAURUS: 'Thesaurus',
  THESAURUS_EDITORIAL: 'Thesaurus Editorial'
}

export const SignUpSources = {
  DICTIONARY: 'UA_Dcom_HP',
  GRAMMAR_COACH: 'GC_Free_Account_Created',
  THESAURUS: 'UA_Tcom_HP'
}

export const TopLinkIds = {
  BRITISH: 'british',
  CULTURAL: 'cultural',
  EXAMPLES: 'examples',
  IDIOMS_AND_PHRASES: 'idioms-and-phrases',
  MORE_ABOUT: 'more-about',
  QUIZ: 'quiz',
  SCIENTIFIC: 'scientific',
  SYNONYMS: 'synonyms',
  TOP_DEFINITIONS: 'top-definitions'
}

// PropTypes
export const PROPTYPE_CHILDREN = oneOfType([arrayOf(node), node])
export const PROPTYPE_SITENAME = oneOf([
  DICTIONARY_SITENAME,
  THESAURUS_SITENAME
])
export const PROPTYPE_MAILING_LISTS = oneOf(Object.values(MailingLists))
export const PROPTYPE_NAVIGATION_GROUP = shape({
  heading: node.isRequired,
  items: arrayOf(
    shape({
      content: node.isRequired,
      id: string.isRequired
    })
  ),
  linkname: string.isRequired
})
