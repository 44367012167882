import { createSelector } from '@reduxjs/toolkit'
import { SOVRN_COUNTRY_CODE_ALLOWLIST } from '~client/constants'
import { TYPE_DESKTOP, TYPE_MOBILE, TYPE_TABLET } from './slice'

export const selectIsDesktop = state =>
  state.userContext?.detectedDeviceHeader === TYPE_DESKTOP
export const selectIsMobile = state =>
  state.userContext?.detectedDeviceHeader === TYPE_MOBILE
export const selectIsTablet = state =>
  state.userContext?.detectedDeviceHeader === TYPE_TABLET

export const selectCountryCode = state => state.userContext?.countryCode

export const selectAllowSovrn = createSelector(
  selectCountryCode,
  countryCode => {
    return SOVRN_COUNTRY_CODE_ALLOWLIST.includes(countryCode)
  }
)
