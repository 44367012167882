import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'

/**
 * Fetches Word of the Day data
 * @returns Promise || null
 */
export const fetchWordOfTheDay = createAsyncThunk(
  'wordpress/fetchWordOfTheDay',
  async () => {
    const endpointUrl = process.env.SAFE_WORDPRESS_WOTD_ENDPOINT
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Synonym of the Day data
 * @returns Promise || null
 */
export const fetchSynonymOfTheDay = createAsyncThunk(
  'wordpress/fetchSynonymOfTheDay',
  async () => {
    const endpointUrl = process.env.SAFE_WORDPRESS_SOTD_ENDPOINT
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Quiz data for individual quiz w/o params
 * @returns Promise || null
 */
export const fetchFeaturedQuiz = createAsyncThunk(
  'wordpress/fetchFeaturedQuiz',
  async () => {
    const endpointUrl = process.env.SAFE_WORDPRESS_QUIZ_ENDPOINT
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Quiz List data
 * @returns Promise || null
 */
export const fetchQuizList = createAsyncThunk(
  'wordpress/fetchQuizList',
  async () => {
    const endpointUrl = process.env.SAFE_WORDPRESS_QUIZ_LIST_ENDPOINT
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Builds the endpoint URL for quiz
 * @param {String{}} word is the quiz slug.
 * @returns {String} quiz api endpoint url
 */
export const createQuizUrl = params => {
  const { quiz: slug } = params
  return `${process.env.SAFE_WORDPRESS_QUIZ_ENDPOINT}?slug=${slug}`
}

/**
 * Fetches Quiz data
 * @param {String{}} params is the quiz slug.
 * @returns Promise || null
 */
export const fetchQuiz = createAsyncThunk(
  'wordpress/fetchQuiz',
  async params => {
    const endpointUrl = createQuizUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

/**
 * Builds the endpoint URL for quiz answers
 * @param {String{}} params is the quiz slug.
 * @returns {String} quiz answers api endpoint url
 */
export const createQuizAnswersUrl = params => {
  const { quiz: slug } = params
  return `${process.env.SAFE_WORDPRESS_QUIZ_ANSWERS_ENDPOINT}?slug=${slug}`
}

/**
 * Fetches Quiz Answers data
 * @param {String{}} params is the quiz slug.
 * @returns Promise || null
 */
export const fetchQuizAnswers = createAsyncThunk(
  'wordpress/fetchQuizAnswers',
  async params => {
    const endpointUrl = createQuizAnswersUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

/**
 * Builds the endpoint URL for editorial articles
 * @param {String{}} params is the article slug.
 * @returns {String} article api endpoint url
 */
export const createEditorialArticleUrl = params => {
  const { slug } = params
  return `${process.env.SAFE_WORDPRESS_EDITORIAL_ARTICLE_ENDPOINT}?slug=${slug}`
}

/**
 * Fetches Editorial Article Page data
 * @returns Promise || null
 */
export const fetchEditorialArticle = createAsyncThunk(
  'wordpress/fetchEditorialArticle',
  async params => {
    const endpointUrl = createEditorialArticleUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const createEditorialNotesUrl = ({word}) => {
  // TODO fix these JSON files so they match slugs. The headword field in WP was incorrect in many cases.
  return `${process.env.SAFE_DCOM_RESULT_EDITORS_NOTES}/${word}.json`
}

export const fetchEditorialNotes = createAsyncThunk(
  'wordpress/fetchEditorialNotes',
  async params => {
    const endpointUrl = createEditorialNotesUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches editorial content data for Word Comparison Page
 * Returns a promise
 */
export const createFetchCompareWordsContent = async versus => {
  const response = await fetch(`${process.env.SAFE_WORDPRESS_WORD_COMPARISON_ENDPOINT}${versus}`)
    .then(data => data.json())
    .catch(err => {
      throw err
    })
    return response
}

/**
 * Builds the endpoint URL for word puzzle
 * @param {String{}} params is the letters used to find the words
 * @returns {String} article api endpoint url
 */
export const createWordPuzzleUrl = puzzleLetters => {
  const letters = puzzleLetters.toString()
  return `${process.env.SAFE_WORDPRESS_WORD_PUZZLE_ENDPOINT}?letters=${letters}`
}

/**
 * Fetches editorial content data for Word Comparison Page
 * @returns Promise || null
 */
export const fetchWordPuzzle = createAsyncThunk(
  'wordpress/fetchWordPuzzle',
  async puzzleLetters => {
    const endpointUrl = createWordPuzzleUrl(puzzleLetters)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)
