export const selectLocationHostname = state => state.location?.hostname
export const selectLocationPathname = state => state.location?.pathname
export const selectLocationPort = state => {
  const port = state.location?.port
  return port ? `:${port}` : ''
}
export const selectLocationProtocol = state => state.location?.protocol
export const selectLocationQuery = state => state.location?.query
export const selectLocationSubdomain = state => state.location?.subdomain
export const selectLocationTld = state => state.location?.tld
export const selectLocationUrl = state => state.location?.url

export const selectSiteName = state => state.location?.name
export const selectDictionaryUrl = state => state.location?.dictionaryUrl
export const selectThesaurusUrl = state => state.location?.thesaurusUrl
