import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import {
  DICTIONARY_URL,
  THESAURUS_URL
} from '~client/constants'
import { A, MinusIcon, PlusIcon } from '~elements'
import { siteMenuData } from '~utils/siteMenuData'
import styles from './MobileNavigationDrawer.module.scss'
import SubMenuItems from './SubMenuItems'

const getMenuData = dictionaryUrl => {
  return [...siteMenuData(dictionaryUrl)]
}

const TopLevelMenuItems = ({
  dictionaryUrl,
  isOpen,
  selectedSite,
  thesaurusUrl
}) => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(null)

  const menuData = getMenuData(
    dictionaryUrl,
    selectedSite,
    thesaurusUrl
  )

  return menuData.map(({ icon, linkName, name, subMenu, url }, menuIndex) => {
    const linkModulePosition = menuIndex + 1
    const isExpanded = menuIndex === selectedMenuIndex
    const hasSubMenu = !!subMenu.length
    const ExpanderIcon = isExpanded ? MinusIcon : PlusIcon
    const menuItemTabIndex = hasSubMenu ? 0 : -1

    const menuItemTopClassNames = cx(styles['menu-item-top'], {
      [styles.separator]: false
    })
    const menuToggleClassNames = cx(styles['menu-item-top-toggle'], {
      [styles['has-submenu']]: hasSubMenu
    })
    const menuItemsContainerClassNames = cx(styles['menu-items-container'], {
      [styles.expanded]: isExpanded
    })
    const loginClassName = cx({
      [styles.login]: false
    })

    const toggleMenu = () => {
      const newMenuIndex = isExpanded ? null : menuIndex
      setSelectedMenuIndex(newMenuIndex)
    }

    const handleKeyDown = e => {
      if (isOpen && e.key === 'Enter') {
        toggleMenu()
      }
    }

    const menuItem = hasSubMenu ? (
      <>
        {icon}
        {name}
        <ExpanderIcon className={styles['expander-icon']} />
      </>
    ) : (
      <A
        className={loginClassName}
        data-analytics="aa-track"
        data-linkmodule="navigation"
        data-linkmodule_position={linkModulePosition}
        data-linkname={linkName}
        data-linktype="cta"
        data-linkurl={url}
        data-location="header"
        data-type="pages-menu"
        href={url}
        tabIndex={0}
      >
        {icon}
        {name}
      </A>
    )

    return (
      <li className={menuItemTopClassNames} key={name}>
        <div
          className={menuToggleClassNames}
          onClick={toggleMenu}
          onKeyDown={handleKeyDown}
          tabIndex={menuItemTabIndex}
        >
          {menuItem}
        </div>
        {hasSubMenu && (
          <ul className={menuItemsContainerClassNames}>
            <SubMenuItems
              isExpanded={isExpanded}
              linkModulePosition={linkModulePosition}
              linkName={linkName}
              subMenu={subMenu}
              url={url}
            />
          </ul>
        )}
      </li>
    )
  })
}

TopLevelMenuItems.propTypes = {
  dictionaryUrl: string,
  isOpen: bool.isRequired,
  selectedSite: string.isRequired,
  thesaurusUrl: string
}

TopLevelMenuItems.defaultProps = {
  dictionaryUrl: DICTIONARY_URL,
  thesaurusUrl: THESAURUS_URL
}

export default TopLevelMenuItems
