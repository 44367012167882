import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_URL,
  PROPTYPE_SITENAME,
  THESAURUS_URL
} from '~client/constants'
import MobileNavigationDrawer from '../MobileNavigationDrawer/MobileNavigationDrawer'
import PagesMenu from '../PagesMenu/PagesMenu'
import Ribbon from '../Ribbon/Ribbon'
import Search from '../Search/Search'
import SitesMenu from '../SitesMenu/SitesMenu'
import styles from './HeaderModule.module.scss'

export const HeaderModule = ({
  dictionaryUrl,
  selectedSite,
  thesaurusUrl
}) => {
  const classNames = cx(styles.root, `${selectedSite}-site`)

  return (
    <header className={classNames}>
      <SitesMenu
        className={styles['sites-menu']}
        dictionaryUrl={dictionaryUrl}
        id="sites-menu"
        selectedSite={selectedSite}
        thesaurusUrl={thesaurusUrl}
      />
      <div className={styles['search-nav-container']}>
        <MobileNavigationDrawer
          className={styles['mobile-nav']}
          dictionaryUrl={dictionaryUrl}
          selectedSite={selectedSite}
          thesaurusUrl={thesaurusUrl}
        />
        <Search
          className={styles['global-search']}
          dictionaryUrl={dictionaryUrl}
          id="global-search"
          selectedSite={selectedSite}
          thesaurusUrl={thesaurusUrl}
        />
        <PagesMenu
          className={styles['pages-menu']}
          dictionaryUrl={dictionaryUrl}
          id="pages-menu"
          selectedSite={selectedSite}
        />
      </div>
      <Ribbon className={styles.ribbon} />
    </header>
  )
}

HeaderModule.propTypes = {
  dictionaryUrl: string,
  selectedSite: PROPTYPE_SITENAME.isRequired,
  thesaurusUrl: string
}

HeaderModule.defaultProps = {
  dictionaryUrl: DICTIONARY_URL,
  thesaurusUrl: THESAURUS_URL
}

export default withStyles(styles)(HeaderModule)
