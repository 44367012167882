// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZgUH10n0TO6or_k2sotW{color:var(--lightgraytext);display:flex;line-height:1.5;list-style:none;margin-left:auto;margin-right:auto}.ZgUH10n0TO6or_k2sotW>*{font-size:16px}.ZgUH10n0TO6or_k2sotW>*:first-child{text-transform:capitalize}.ZgUH10n0TO6or_k2sotW li:not(:last-of-type)::after{content:"/";margin:0 4px}.ZgUH10n0TO6or_k2sotW a{color:var(--lightgraytext);text-decoration:none}.ZgUH10n0TO6or_k2sotW a:hover{color:var(--lightgraytext);text-decoration:underline}@media(min-width: 1088px){.CetDbL39Jq9UEU0NbhPp{margin:0;padding-left:25px}}@media(min-width: 1600px){.oT5VumbKsN8s6FonUioX{margin:0;padding:12px 0 0 18%}}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/Breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CAEA,wBACE,cAAA,CAEA,oCACE,yBAAA,CAIJ,mDACE,WAAA,CACA,YAAA,CAGF,wBACE,0BAAA,CACA,oBAAA,CAEA,8BACE,0BAAA,CACA,yBAAA,CAaN,0BACE,sBACE,QAAA,CACA,iBAAA,CAAA,CAIJ,0BACE,sBACE,QAAA,CACA,oBAAA,CAAA","sourcesContent":[".root {\n  color: var(--lightgraytext);\n  display: flex;\n  line-height: 1.5;\n  list-style: none;\n  margin-left: auto;\n  margin-right: auto;\n\n  & > * {\n    font-size: 16px;\n\n    &:first-child {\n      text-transform: capitalize;\n    }\n  }\n\n  li:not(:last-of-type)::after {\n    content: '/';\n    margin: 0 4px;\n  }\n\n  a {\n    color: var(--lightgraytext);\n    text-decoration: none;\n\n    &:hover {\n      color: var(--lightgraytext);\n      text-decoration: underline;\n    }\n  }\n}\n\n/* BELONGS IN THE CSS FOR THE FUTURE RESULTS PAGE\n.tcom-results {\n  font-size: 14px;\n  max-width: 850px;\n  padding: 8px 10px;\n}\n*/\n\n@media (min-width: 1088px) {\n  .tcom-results {\n    margin: 0;\n    padding-left: 25px;\n  }\n}\n\n@media (min-width: 1600px) {\n  .compare-words {\n    margin: 0;\n    padding: 12px 0 0 18%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ZgUH10n0TO6or_k2sotW`,
	"tcom-results": `CetDbL39Jq9UEU0NbhPp`,
	"compare-words": `oT5VumbKsN8s6FonUioX`
};
module.exports = ___CSS_LOADER_EXPORT___;
