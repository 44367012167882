// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y6zVexwdzxoTObY_TRi1 p{color:var(--lightgray);font-size:15px;line-height:22px;margin-bottom:0}.Y6zVexwdzxoTObY_TRi1 span{color:var(--lightgraytext);font-size:10px;line-height:17px;text-transform:uppercase}.Y6zVexwdzxoTObY_TRi1 a{color:var(--lightgraytext)}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/ExampleSentence/ExampleSentence.module.scss"],"names":[],"mappings":"AACE,wBACE,sBAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAGF,2BACE,0BAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CAGF,wBACE,0BAAA","sourcesContent":[".root {\n  p {\n    color: var(--lightgray);\n    font-size: 15px;\n    line-height: 22px;\n    margin-bottom: 0;\n  }\n\n  span {\n    color: var(--lightgraytext);\n    font-size: 10px;\n    line-height: 17px;\n    text-transform: uppercase;\n  }\n\n  a {\n    color: var(--lightgraytext);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Y6zVexwdzxoTObY_TRi1`
};
module.exports = ___CSS_LOADER_EXPORT___;
