import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './Icon.module.scss'

const Icon = ({ alt, className, src, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <img
      className={classNames}
      src={src}
      {...props}
      data-type="icon"
      role="img"
    />
  )
}

Icon.propTypes = {
  alt: string,
  className: string,
  src: string.isRequired
}

Icon.defaultProps = {
  alt: 'icon',
  className: null
}

export default withStyles(styles)(Icon)
