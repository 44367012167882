// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t_CzTiqmP1qZBHlfeoga{height:100%;width:100%}.t_CzTiqmP1qZBHlfeoga circle{animation:H9jWWjGSaRQTPBQ3dhCn 1s infinite alternate;fill:#1f389c;opacity:0}.t_CzTiqmP1qZBHlfeoga circle:nth-child(1){animation-delay:666ms;cx:calc(50% + 12px)}.t_CzTiqmP1qZBHlfeoga circle:nth-child(2){animation-delay:333ms;cx:50%}.t_CzTiqmP1qZBHlfeoga circle:nth-child(3){cx:calc(50% - 12px)}@keyframes H9jWWjGSaRQTPBQ3dhCn{from{opacity:0;transform:translateY(-12.5%)}to{opacity:1;transform:translateY(5%)}}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/LoadingDots/LoadingDots.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CAEA,6BACE,oDAAA,CACA,YAAA,CACA,SAAA,CAEA,0CACE,qBAAA,CACA,mBAAA,CAGF,0CACE,qBAAA,CACA,MAAA,CAGF,0CACE,mBAAA,CAKN,gCACE,KACE,SAAA,CACA,4BAAA,CAGF,GACE,SAAA,CACA,wBAAA,CAAA","sourcesContent":[".root {\n  height: 100%;\n  width: 100%;\n\n  circle {\n    animation: bounce 1s infinite alternate;\n    fill: #1f389c;\n    opacity: 0;\n\n    &:nth-child(1) {\n      animation-delay: 666ms;\n      cx: calc(50% + 12px);\n    }\n\n    &:nth-child(2) {\n      animation-delay: 333ms;\n      cx: 50%;\n    }\n\n    &:nth-child(3) {\n      cx: calc(50% - 12px);\n    }\n  }\n}\n\n@keyframes bounce {\n  from {\n    opacity: 0;\n    transform: translateY(-12.5%);\n  }\n\n  to {\n    opacity: 1;\n    transform: translateY(5%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `t_CzTiqmP1qZBHlfeoga`,
	"bounce": `H9jWWjGSaRQTPBQ3dhCn`
};
module.exports = ___CSS_LOADER_EXPORT___;
