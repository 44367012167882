// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.huceYtOhd6GZAbq8gwhn{display:flex;flex-direction:column;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/ModalContent/ModalContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `huceYtOhd6GZAbq8gwhn`
};
module.exports = ___CSS_LOADER_EXPORT___;
