import { useEffect } from 'react'

const useOnClickOutside = (ref, callback) => {
  const onClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e)
    }
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutside)

    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  }, [callback, ref])
}

export default useOnClickOutside
