import { createSlice } from '@reduxjs/toolkit'
import { fetchUserDataContext } from './actions'
import initialState from './state.json'

export const TYPE_DESKTOP = 'Desktop'
export const TYPE_MOBILE = 'Mobile'
export const TYPE_TABLET = 'Tablet'

export const userContextSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchUserDataContext.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchUserDataContext.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        state.status = 'succeeded'
      })
      .addCase(fetchUserDataContext.rejected, state => {
        state.status = 'failed'
      })
  },
  initialState,
  name: 'userContext',
  reducers: {
    setDetectedDeviceHeader: (state, { payload }) => {
      state.detectedDeviceHeader = payload
    }
  }
})

export const actions = userContextSlice.actions

export default userContextSlice.reducer
