import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import DefaultLink from '~elements/DefaultLink/DefaultLink'
import styles from './CtaLink.module.scss'

const CtaLink = ({ children, className, href, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <DefaultLink className={classNames} href={href} {...props}>
      {children}
    </DefaultLink>
  )
}

CtaLink.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  href: string.isRequired
}

CtaLink.defaultProps = {
  className: null
}

export default withStyles(styles)(CtaLink)
