import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsMobile } from '~store/userContext/selectors'

const WunderkindContainer = () => {
  const isMobile = useSelector(selectIsMobile)

  const showWunderkindAd = !isMobile ? <div data-wknd-infeed-div /> : null

  return showWunderkindAd
}

export default WunderkindContainer
