import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'

/**
 * Fetches user context data
 * @returns Promise || null
 */
export const fetchUserDataContext = createAsyncThunk(
  'userContext/fetchUserDataContext',
  async () => {
    const endpointUrl = 'https://www.dictionary.com/user-data-context/v3'
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)
