import {
  ClickEvent,
  ClickInnerCancelSubscriptionEvent,
  ClickInnerEvent,
  QuizResponseEvent
} from '@dictionary.com/dcom-analytics'

let tracker

export default tracker
export const setTracker = newTracker => {
  tracker = newTracker
  return tracker
}
export const report = (events, callback) => tracker?.report(events, callback)
export const reportClick = (target, callback) =>
  tracker?.report(new ClickEvent(target), callback)
export const reportClickInner = (target, callback) =>
  tracker?.report(new ClickInnerEvent(target), callback)
export const reportQuizResponse = ({
  contentTitle,
  questionNum,
  questionID,
  answerSelected,
  quizType,
  isAnswerCorrect
}) =>
  tracker?.report(
    new QuizResponseEvent('q', {
      answerSelected,
      contentTitle,
      isAnswerCorrect,
      questionID,
      questionNum,
      quizType
    })
  )

export const reportCancelSubscription = (target, cancelReason, callback) => {
  tracker.current?.report(
    new ClickInnerCancelSubscriptionEvent(target, false, cancelReason),
    callback
  )
}
