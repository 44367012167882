import { createSlice } from '@reduxjs/toolkit'
import initialState from './state.json'

export const carambolaAdSlice = createSlice({
  initialState,
  name: 'carambolaAd',
  reducers: {
    setCarambolaAd: (state, { payload }) => {
      state.carambolaAd = payload
    }
  }
})

export const actions = carambolaAdSlice.actions

export default carambolaAdSlice.reducer
