import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'

const GRAPHQL_ENDPOINT = process.env.SAFE_GRAPHQL_ENDPOINT

const GET_WORD_LIST = `
  query WordList($field: String!, $order: SortOrder!, $shortId: ID) {
    wordList(shortId: $shortId) {
      category
      color
      description
      id
      name
      shortId
      user {
        isApprovedEditor
      }
      userId
      words(sort: { field: $field, order: $order }) {
        createdAt
        definition
        definitionPath
        displayForm
        isLicensed
        isValidDcom
        isValidTcom
        pos
        pronunciationAudio
        slug
      }
    }
  }
`
const parseGraphQLResponse = async response => {
  const data = await response.json()
  if (data.errors) {
    throw new Error(data.errors[0]?.message)
  }
  return data
}

export const fetchWordList = createAsyncThunk(
  'fetchWordList',
  async ({ shortId }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: GET_WORD_LIST,
        variables: {
          field: 'createdAt',
          order: 'DESC',
          shortId
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)
