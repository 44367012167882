const parseCustomStyleProps = (ref, data = {}, styleMap = {}) => {
  const { style } = ref || {}

  if (style) {
    const keys = Object.keys(styleMap)

    Object.keys(data)
      .filter(ele => keys.includes(ele))
      .forEach(ele => {
        const { property, transform } = styleMap[ele]
        const value =
          typeof transform !== 'function' ? data[ele] : transform(data[ele])

        style.setProperty(`--${property}`, value)
      })
  }
}

export default parseCustomStyleProps
