import { createSlice } from '@reduxjs/toolkit'
import {
  fetchEditorialArticle,
  fetchEditorialNotes,
  fetchFeaturedQuiz,
  fetchQuiz,
  fetchQuizAnswers,
  fetchQuizList,
  fetchSynonymOfTheDay,
  fetchWordOfTheDay,
  fetchWordPuzzle
} from './actions'
import initialState from './state.json'

export const wordpressSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchSynonymOfTheDay.pending, state => {
        state.synonymoftheday.status = 'loading'
      })
      .addCase(fetchSynonymOfTheDay.fulfilled, (state, action) => {
        state.synonymoftheday = action.payload.synonymoftheday
        state.synonymoftheday.status = 'succeeded'
      })
      .addCase(fetchSynonymOfTheDay.rejected, (state, action) => {
        state.synonymoftheday.status = 'failed'
        state.synonymoftheday.error = action.error.message
      })
      .addCase(fetchWordOfTheDay.pending, state => {
        state.wordoftheday.status = 'loading'
      })
      .addCase(fetchWordOfTheDay.fulfilled, (state, action) => {
        state.wordoftheday = action.payload.wordoftheday
        state.wordoftheday.status = 'succeeded'
      })
      .addCase(fetchWordOfTheDay.rejected, (state, action) => {
        state.wordoftheday.status = 'failed'
        state.wordoftheday.error = action.error.message
      })
      .addCase(fetchQuiz.pending, state => {
        state.quiz = {
          status: 'loading'
        }
      })
      .addCase(fetchQuiz.fulfilled, (state, action) => {
        state.quiz = {
          data: action.payload,
          status: 'succeeded'
        }
      })
      .addCase(fetchQuiz.rejected, (state, action) => {
        state.quiz = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchFeaturedQuiz.pending, state => {
        state.featuredQuiz = {
          status: 'loading'
        }
      })
      .addCase(fetchFeaturedQuiz.fulfilled, (state, action) => {
        state.featuredQuiz = {
          data: action.payload,
          status: 'succeeded'
        }
      })
      .addCase(fetchFeaturedQuiz.rejected, (state, action) => {
        state.featuredQuiz = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchQuizAnswers.pending, state => {
        state.quizAnswers = {
          status: 'loading'
        }
      })
      .addCase(fetchQuizAnswers.fulfilled, (state, action) => {
        state.quizAnswers = {
          data: action.payload,
          status: 'succeeded'
        }
      })
      .addCase(fetchQuizAnswers.rejected, (state, action) => {
        state.quizAnswers = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchQuizList.pending, state => {
        state.quizList = {
          status: 'loading'
        }
      })
      .addCase(fetchQuizList.fulfilled, (state, action) => {
        state.quizList = {
          items: action.payload,
          status: 'succeeded'
        }
      })
      .addCase(fetchQuizList.rejected, (state, action) => {
        state.quizList = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchEditorialArticle.pending, (state, action) => {
        state.article = { status: 'loading' }
      })
      .addCase(fetchEditorialArticle.fulfilled, (state, action) => {
        state.article = {
          data: action.payload,
          status: 'succeeded'
        }
      })
      .addCase(fetchEditorialArticle.rejected, (state, action) => {
        state.article = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchEditorialNotes.pending, (state, action) => {
        state.editorialNotes = { status: 'loading' }
      })
      .addCase(fetchEditorialNotes.fulfilled, (state, action) => {
        state.editorialNotes = {
          data: action.payload,
          status: 'succeeded'
        }
      })
      .addCase(fetchEditorialNotes.rejected, (state, action) => {
        state.editorialNotes = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchWordPuzzle.pending, (state, action) => {
        state.wordPuzzle = { status: 'loading' }
      })
      .addCase(fetchWordPuzzle.fulfilled, (state, action) => {
        state.wordPuzzle = {
          data: action.payload.data,
          status: 'succeeded'
        }
      })
      .addCase(fetchWordPuzzle.rejected, (state, action) => {
        state.wordPuzzle = {
          error: action.error.message,
          status: 'failed'
        }
      })
  },
  initialState,
  name: 'wordpress',
  reducers: {
    resetWordpressSlice: () => initialState
  }
})

export const actions = wordpressSlice.actions

export default wordpressSlice.reducer
