import React from 'react'
import { arrayOf, node } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './ResultsPageLayout.module.scss'

export const ResultsPageLayout = ({ children }) => {
  return <div className={styles.root}>{children}</div>
}

ResultsPageLayout.propTypes = {
  children: arrayOf(node).isRequired // Expects exactly three divs
}

export default withStyles(styles)(ResultsPageLayout)
