// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fX2hKWJdzQtrD17x9YUt{display:flex}.fX2hKWJdzQtrD17x9YUt h6{display:inline;font-size:inherit;font-weight:inherit;margin:0;width:fit-content}.fX2hKWJdzQtrD17x9YUt menu{align-items:baseline;display:inline-flex;flex-flow:row wrap;list-style-type:none;margin:0;padding:0}.fX2hKWJdzQtrD17x9YUt menu a{text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/AzLinkMenu/AzLinkMenu.module.scss","webpack://./src/client/globals.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CAEA,yBACE,cAAA,CACA,iBAAA,CACA,mBAAA,CACA,QAAA,CACA,iBAAA,CAGF,2BCVA,oBAAA,CACA,mBAAA,CACA,kBAAA,CACA,oBAAA,CACA,QAAA,CACA,SAAA,CDQE,6BACE,yBAAA","sourcesContent":["@import '~client/globals';\n\n.root {\n  display: flex;\n\n  h6 {\n    display: inline;\n    font-size: inherit;\n    font-weight: inherit;\n    margin: 0;\n    width: fit-content;\n  }\n\n  menu {\n    @include horizontal-list;\n\n    a {\n      text-transform: capitalize;\n    }\n  }\n}\n","/* THIS FILE SHOULD ONLY CONTAIN SCSS. AVOID CSS-ONLY FEATURES */\n\n@mixin horizontal-list {\n  align-items: baseline;\n  display: inline-flex;\n  flex-flow: row wrap;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n@mixin thesaurus-modules {\n  --thesaurus-modules-margin: 12px;\n\n  margin: 16px var(--thesaurus-modules-margin);\n  max-width: 846px;\n  width: calc(100% - var(--thesaurus-modules-margin) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `fX2hKWJdzQtrD17x9YUt`
};
module.exports = ___CSS_LOADER_EXPORT___;
