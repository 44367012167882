import { createSlice } from '@reduxjs/toolkit'
import getSiteContext from '~utils/getSiteContext'
import initialState from './state.json'

const LOCAL_HOSTNAME = 'localhost'
const LOCALHOST_ERROR =
  'Development won\'t work via localhost. In your /etc/hosts file, you must map 127.0.0.1 to both "local.dictionary.com" and "local.thesaurus.com" domains.'

export const DICTIONARY_SITENAME = 'dictionary'
export const THESAURUS_SITENAME = 'thesaurus'

export const locationSlice = createSlice({
  initialState,
  name: 'location',
  reducers: {
    setLocation: (state, { payload }) => {
      const {
        dictionaryUrl,
        hostname,
        name,
        pathname,
        port,
        protocol,
        query,
        subdomain,
        thesaurusUrl,
        tld,
        url
      } = getSiteContext(payload)

      if (hostname === LOCAL_HOSTNAME) throw Error(LOCALHOST_ERROR)

      state.hostname = hostname
      state.pathname = pathname
      state.port = port
      state.protocol = protocol
      state.query = query
      state.name = name
      state.dictionaryUrl = dictionaryUrl
      state.thesaurusUrl = thesaurusUrl
      state.subdomain = subdomain
      state.tld = tld
      state.url = url
    }
  }
})

export const actions = locationSlice.actions

export default locationSlice.reducer
