import { createSlice } from '@reduxjs/toolkit'
import {
  fetchWordList
} from './actions'
import initialState from './state.json'

export const accountsSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchWordList.pending, state => {
        state.wordList.status = 'loading'
        state.wordListStatus.type = 'fetchWordList'
      })
      .addCase(fetchWordList.fulfilled, (state, action) => {
        state.wordList = action.payload?.data?.wordList
        state.wordList.status = 'success'
      })
      .addCase(fetchWordList.rejected, (state, action) => {
        state.wordList = {
          error: action.error.message,
          status: 'failed'
        }
      })
  },
  initialState,
  name: 'accounts'
})

export const actions = accountsSlice.actions

export default accountsSlice.reducer
