import React from 'react'
import { oneOf, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './TopicLabel.module.scss'

const labelSizings = {
  default: 'default',
  large: 'large',
  responsive: 'responsive'
}

const TopicLabel = ({ className, text, size }) => {
  const classNames = cx(styles.root, className, {
    [styles[size]]: size
  })

  return <p className={classNames}>{text}</p>
}

TopicLabel.propTypes = {
  className: string,
  size: oneOf([
    labelSizings.default,
    labelSizings.large,
    labelSizings.responsive
  ]),
  text: string.isRequired
}

TopicLabel.defaultProps = {
  className: null,
  size: labelSizings.responsive
}

export default withStyles(styles)(TopicLabel)
