// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dqDi0srdZ8k9untRSDYQ{width:100%}.dqDi0srdZ8k9untRSDYQ progress[value]{border-radius:10px;height:10px;width:100%}.dqDi0srdZ8k9untRSDYQ progress[value]::-webkit-progress-bar{background:rgba(255,255,255,.2);border-radius:10px}.dqDi0srdZ8k9untRSDYQ progress[value]::-webkit-progress-value{background:var(--teal);border-radius:10px;transition:width .5s}.dqDi0srdZ8k9untRSDYQ progress[value]::-moz-progress-bar{background:var(--teal);border-radius:10px}.dqDi0srdZ8k9untRSDYQ p{color:#fff;font-weight:bold;margin:8px 0 16px}.eFtqAEuJrzX_DK3pHoXG::before{background-color:var(--teal);clip-path:circle(50% at 50% 50%);content:"";height:10px;position:absolute;transition:all 600ms ease-out 0s;width:10px;z-index:2}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/ProgressBar/ProgressBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,sCACE,kBAAA,CACA,WAAA,CACA,UAAA,CAEA,4DACE,+BAAA,CACA,kBAAA,CAGF,8DACE,sBAAA,CACA,kBAAA,CACA,oBAAA,CAGF,yDACE,sBAAA,CACA,kBAAA,CAIJ,wBACE,UAAA,CACA,gBAAA,CACA,iBAAA,CAIJ,8BACE,4BAAA,CACA,gCAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,gCAAA,CACA,UAAA,CACA,SAAA","sourcesContent":[".root {\n  width: 100%;\n\n  progress[value] {\n    border-radius: 10px;\n    height: 10px;\n    width: 100%;\n\n    &::-webkit-progress-bar {\n      background: #fff3;\n      border-radius: 10px;\n    }\n\n    &::-webkit-progress-value {\n      background: var(--teal);\n      border-radius: 10px;\n      transition: width 0.5s;\n    }\n\n    &::-moz-progress-bar {\n      background: var(--teal);\n      border-radius: 10px;\n    }\n  }\n\n  p {\n    color: #fff;\n    font-weight: bold;\n    margin: 8px 0 16px;\n  }\n}\n\n.progress::before {\n  background-color: var(--teal);\n  clip-path: circle(50% at 50% 50%);\n  content: '';\n  height: 10px;\n  position: absolute;\n  transition: all 600ms ease-out 0s;\n  width: 10px;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dqDi0srdZ8k9untRSDYQ`,
	"progress": `eFtqAEuJrzX_DK3pHoXG`
};
module.exports = ___CSS_LOADER_EXPORT___;
