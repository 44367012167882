import React from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './RedesignButton.module.scss'

const RedesignButton = ({
  children,
  className,
  disabled,
  success,
  successMessage,
  ...props
}) => {
  const classNames = cx(styles.root, className, {
    [styles.success]: !!success
  })

  return (
    <button className={classNames} disabled={disabled} {...props}>
      {success ? successMessage : children}
    </button>
  )
}

RedesignButton.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  disabled: bool,
  success: bool,
  successMessage: string
}

RedesignButton.defaultProps = {
  className: null,
  disabled: false,
  success: false,
  successMessage: "You've been subscribed!"
}

export default withStyles(styles)(RedesignButton)
