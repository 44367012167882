const mailingListSignUp = ({ email, token, error, mailingList, success }) => {
  const body = JSON.stringify({
    consentToTrack: 'Yes',
    email,
    recaptchaToken: token,
    subscribeTo: [mailingList]
  })

  fetch(process.env.SAFE_EMAIL_PREFERENCES_ENDPOINT, {
    body,
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST'
  })
    .then(res => {
      if (res.status > 302) throw new Error()
      if (success) success()
    })
    .catch(() => {
      if (error) error()
    })
}

export default mailingListSignUp
