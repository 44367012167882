import { createSlice } from '@reduxjs/toolkit'
import initialState from './state.json'

export const localDataSlice = createSlice({
  initialState,
  name: 'localData',
  reducers: {}
})

export const actions = localDataSlice.actions

export default localDataSlice.reducer
