import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'
import { createFetchCompareWordsContent } from '~store/wordpress/actions'
import { createFetchDictionaryResults } from '~store/lexigraph/actions'

const BROWSE_RESULTS_PER_PAGE = 300

export const createLunaBrowseUrl = ({ letter, page = 1 }) => {
  const offset = (page - 1) * BROWSE_RESULTS_PER_PAGE
  return `${process.env.SAFE_LUNA_LIST_ENDPOINT}/${letter}?offset=${offset}&limit=${BROWSE_RESULTS_PER_PAGE}`
}

export const fetchLunaBrowse = createAsyncThunk(
  'fetchLunaBrowse',
  async ({ letter, page }) => {
    const endpointUrl = createLunaBrowseUrl({ letter, page })
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

/**
 * Fetches all word comparisons by word slug
 */
export const fetchWordComparisonsBySlug = createAsyncThunk(
  'fetchWordComparisonsBySlug',
  async slug => {
    const response = await fetch(`${process.env.SAFE_WORD_COMPARISONS_ENDPOINT}/by-slug/${slug}`)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const fetchWordComparisonPageData = createAsyncThunk(
  'fetchWordComparisonPageData',
  async id => {
    const wordComparison = await fetch(`${process.env.SAFE_WORD_COMPARISONS_ENDPOINT}/${id}`)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    
    if (!wordComparison) return

    const fetches = [
      createFetchCompareWordsContent(id)
    ]
    fetches.push(...wordComparison.words.map(word => createFetchDictionaryResults(word.slug)))
    const [compareWordsContent, ...dictionaryResults] = await Promise.all(fetches)
    return {
      compareWordsContent,
      dictionaryResults,
      wordComparison
    }
  }
)

export const createResultsDataUrl = word => {
  const entryWord = word.word
  return `${process.env.SAFE_DCOM_PAGE_DATA_ENDPOINT}/${entryWord}`
}

/**
 * Fetches dictionary results data from the pageData api
 * @param {String} word is the results word
 * @returns Promise || null
 */
export const fetchResultsData = createAsyncThunk(
  'fetchResultsData',
  async word => {
    const endpointUrl = createResultsDataUrl(word)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

export const createWordsRelatedUrl = word => {
  const entryWord = word.word
  return `${process.env.SAFE_DCOM_RESULTS_WORDS_RELATED_DATA_ENDPOINT}/${entryWord}`
}

/**
 * Fetches dictionary definition data from the Words Related api
 * @param {String} word is the definition word
 * @returns Promise || null
 */
export const fetchWordsRelated = createAsyncThunk(
  'fetchWordsRelated',
  async word => {
    const endpointUrl = createWordsRelatedUrl(word)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Example Sentences for Result page
 * @params {String} word is the results word
 * @returns Promise || null
 */

export const createExampleSentenceUrl = ({ word }) => {
  const params = `?filterQuery=${word}&perPage=5`
  return `${process.env.SAFE_EXAMPLE_SENTENCES_ENDPOINT}${params}`
}

export const fetchExampleSentences = createAsyncThunk(
  'luna/fetchExampleSentences',
  async params => {
    const endpointUrl = createExampleSentenceUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Words Nearby for Results page
 * @params {String} word is the entry word in the results page
 * @returns Promise || null
 */

export const createWordsNearbyUrl = ({ word }) => {
  return `${process.env.SAFE_LUNA_WORDS_NEARBY_ENDPOINT}/${word}`
}

export const fetchWordsNearby = createAsyncThunk(
  'fetchWordsNearby',
  async params => {
    const endpointUrl = createWordsNearbyUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Words Nearby for Results page
 * @params {String} word is the entry word in the results page
 * @returns Promise || null
 */

export const createSpellSuggestionsUrl = ({ word }) => {
  return `${process.env.SAFE_SPELL_SUGGESTIONS_URL}/${word}`
}

export const fetchSpellSuggestions = createAsyncThunk(
  'fetchSpellSuggestions',
  async params => {
    const endpointUrl = createSpellSuggestionsUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)
