import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './SubjectLabel.module.scss'

const SubjectLabel = ({ className, text, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <div className={classNames} {...props}>
      {text}
    </div>
  )
}

SubjectLabel.propTypes = {
  className: string,
  text: string.isRequired
}

SubjectLabel.defaultProps = {
  className: null
}

export default withStyles(styles)(SubjectLabel)
