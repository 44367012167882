import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { EMAIL_VALIDATION_REGEX } from '~client/constants'
import { getAllowedProps } from '~utils/props'
import styles from './TextInput.module.scss'

const validateEmail = email => EMAIL_VALIDATION_REGEX.test(email)

const TextInput = ({
  errorText,
  isValid,
  label,
  name,
  placeholder,
  required,
  type,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')
  const [validationMessage, setValidationMessage] = useState('')

  const validateInput = ({ target: { value } }) => {
    setInputValue(value)
    const isEmail = type === 'email'
    const isValidInput = isEmail
      ? validateEmail(value)
      : value.trim().length > 0

    if (isValidInput) {
      setValidationMessage('')
    } else {
      setValidationMessage(errorText)
    }
  }

  const isInvalidInput = !!validationMessage || (!isValid && !inputValue)

  const classNames = cx(styles.root, {
    [styles['validation-error-container']]: isInvalidInput
  })

  return (
    <div className={classNames} data-type="text-input">
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        onBlur={validateInput}
        onChange={validateInput}
        placeholder={placeholder}
        required={required}
        type={type}
        value={inputValue}
        {...getAllowedProps(props)}
      />
      {isInvalidInput && <p>{errorText || validationMessage}</p>}
    </div>
  )
}

TextInput.propTypes = {
  errorText: string,
  isValid: bool,
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  required: bool,
  type: string
}

TextInput.defaultProps = {
  errorText: 'This field is required.',
  isValid: true,
  placeholder: null,
  required: true,
  type: 'text'
}

export default withStyles(styles)(TextInput)
