import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'

const BROWSE_RESULTS_PER_PAGE = 300

/**
 * Builds the endpoint URL for BrowsePage data
 * @param {String} letter list letter
 * @param {Number} page page number of BrowsePage for given letter
 * @returns {String} BrowsePage data api endpoint url
 */
export const createTunaBrowseUrl = ({ letter, page = 1 }) => {
  const offset = (page - 1) * BROWSE_RESULTS_PER_PAGE

  return `${process.env.SAFE_TUNA_LIST_ENDPOINT}/${letter}?offset=${offset}&limit=${BROWSE_RESULTS_PER_PAGE}`
}

/**
 * Fetches data for BrowsePage
 * @param {String} letter list letter
 * @param {Number} page page number of BrowsePage for given letter
 * @returns Promise || null
 */
export const fetchTunaBrowse = createAsyncThunk(
  'fetchTunaBrowse',
  async ({ letter, page }) => {
    const endpointUrl = createTunaBrowseUrl({ letter, page })
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

export const createTunaEditorialNotesUrl = ({word}) => {
  // TODO fix these JSON files so they match slugs. The headword field in WP was incorrect in many cases.
  return `${process.env.SAFE_TCOM_EDITORIAL_NOTES_ENDPOINT}/${word}.json`
}

export const fetchTunaEditorialNotes = createAsyncThunk(
  'fetchTunaEditorialNotes',
  async params => {
    const endpointUrl = createTunaEditorialNotesUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Builds the endpoint URL for Tuna Page Data
 * @param {String[]} word Index 0 is the root word
 * @returns {String} Tuna page data api endpoint url
 */
export const createTunaResultsUrl = word => {
  return `${process.env.SAFE_TUNA_RESULTS_ENDPOINT}/${word}`
}

/**
 * Fetches data on synonyms from Tuna
 * @param {String[]} word Index 0 is the root word
 * @returns Promise || null
 */
export const fetchTunaResults = createAsyncThunk(
  'fetchTunaResults',
  async word => {
    const endpointUrl = createTunaResultsUrl(word)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)
