// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U_9UfTepP2dQbPHX661u{background-color:var(--aard-color-bkgd-info);font-weight:600;height:48px;padding:12px;width:fit-content}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/CtaLink/CtaLink.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".root {\n  background-color: var(--aard-color-bkgd-info);\n  font-weight: 600;\n  height: 48px;\n  padding: 12px;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `U_9UfTepP2dQbPHX661u`
};
module.exports = ___CSS_LOADER_EXPORT___;
