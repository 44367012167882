// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kVbwiv7LqBtZUINE6ehS{align-items:center;display:flex;text-align:center}.kVbwiv7LqBtZUINE6ehS::before,.kVbwiv7LqBtZUINE6ehS::after{border-bottom:.5px solid var(--warmgray);content:"";flex:1}.kVbwiv7LqBtZUINE6ehS:not(:empty)::before{margin-right:10px}.kVbwiv7LqBtZUINE6ehS:not(:empty)::after{margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/HorizontalRuleTitle/HorizontalRuleTitle.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,iBAAA,CAGF,2DAEE,wCAAA,CACA,UAAA,CACA,MAAA,CAGF,0CACE,iBAAA,CAGF,yCACE,gBAAA","sourcesContent":[".horizontal-rule-title {\n  align-items: center;\n  display: flex;\n  text-align: center;\n}\n\n.horizontal-rule-title::before,\n.horizontal-rule-title::after {\n  border-bottom: 0.5px solid var(--warmgray);\n  content: '';\n  flex: 1;\n}\n\n.horizontal-rule-title:not(:empty)::before {\n  margin-right: 10px;\n}\n\n.horizontal-rule-title:not(:empty)::after {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal-rule-title": `kVbwiv7LqBtZUINE6ehS`
};
module.exports = ___CSS_LOADER_EXPORT___;
