import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_URL_TERMS,
  EMAIL_VALIDATION_REGEX,
  MAILING_LIST_TERMS_COPY,
  MAILING_LIST_TERMS_LINK_COPY,
  PROPTYPE_MAILING_LISTS
} from '~client/constants'
import { A, Button } from '~elements'
import mailingListSignUp from '~utils/mailingListSignUp'
import styles from './SignUpForm.module.scss'

const DEFAULT_ERROR_MESSAGE = 'Uh oh! An unknown error occurred.'
const DEFAULT_STATE = 'DEFAULT STATE'
const DEFAULT_SUCCESS_MESSAGE =
  "You've been subscribed. New words are on the way!"
const ERROR_STATE = 'ERROR STATE'
const SUCCESS_STATE = 'SUCCESS STATE'
const VALID_STATE = 'VALID STATE'

const reCaptchaApiKey = process.env.SAFE_RECAPTCHA_API_KEY

export const SignUpForm = ({
  className,
  errorMessage,
  isFooterSignUp,
  mailingList,
  successMessage
}) => {
  const [value, setValue] = useState('')
  const [status, setStatus] = useState(DEFAULT_STATE)
  const [message, setMessage] = useState()
  const classNames = cx([
    styles.root,
    className,
    {
      [styles.error]: status === ERROR_STATE,
      [styles.success]: [SUCCESS_STATE, VALID_STATE].includes(status)
    }
  ])

  const validateEmail = email => EMAIL_VALIDATION_REGEX.test(email)

  const onChange = e => {
    const { value } = e.target
    setValue(value)

    const isEmailValid = validateEmail(value)

    if (isEmailValid) {
      setStatus(VALID_STATE)
      setMessage('Valid email address')
      return
    }

    setMessage()
  }

  const onSubmit = e => {
    e.preventDefault()

    const isEmailValid = validateEmail(value)

    if (!isEmailValid) {
      setStatus(ERROR_STATE)
      setMessage(errorMessage)
      return
    }
    window.grecaptcha.enterprise.ready(function () {
      window.grecaptcha.enterprise
        .execute(reCaptchaApiKey, { action: 'login' })
        .then(function (token) {
          mailingListSignUp({
            email: value,
            error: () => {
              setStatus(ERROR_STATE)
              setMessage(DEFAULT_ERROR_MESSAGE)
            },
            mailingList,
            success: () => {
              setStatus(SUCCESS_STATE)
              setMessage(successMessage)
            },
            token
          })
        })
    })
  }

  return (
    <div className={styles.root} data-type="signup-form">
      <form className={classNames} onSubmit={onSubmit}>
        <div className={styles['field-background']}>
          <input
            aria-autocomplete="list"
            autoCapitalize="none"
            autoComplete="on"
            autoCorrect="off"
            name="sign-up"
            onChange={onChange}
            placeholder="Enter your email address"
            spellCheck="false"
            type="text"
            value={value}
          />
          <Button
            className={styles['submit-button']}
            data-analytics="aa-track"
            data-linkmodule="sign-up"
            data-linkname="email-submit"
            data-linktype="cta"
            data-location="footer"
            data-type="sign-up"
            type="submit"
          >
            Sign up
          </Button>
        </div>
        {message && (
          <aside className={isFooterSignUp && styles['footer-signup']}>
            {message}
          </aside>
        )}
      </form>
      <p className={styles['terms-copy']}>
        {`${MAILING_LIST_TERMS_COPY} `}
        <A href={DICTIONARY_URL_TERMS}>{MAILING_LIST_TERMS_LINK_COPY}</A>
      </p>
    </div>
  )
}

SignUpForm.propTypes = {
  className: string,
  errorMessage: string,
  isFooterSignUp: bool,
  mailingList: PROPTYPE_MAILING_LISTS.isRequired,
  successMessage: string
}

SignUpForm.defaultProps = {
  className: null,
  errorMessage: DEFAULT_ERROR_MESSAGE,
  isFooterSignUp: null,
  successMessage: DEFAULT_SUCCESS_MESSAGE
}

export default withStyles(styles)(SignUpForm)
