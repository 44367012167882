import { createSlice } from '@reduxjs/toolkit'
import {
  fetchTunaBrowse,
  fetchTunaEditorialNotes,
  fetchTunaResults
} from './actions'
import initialState from './state.json'

export const BROWSE_RESULTS_PER_PAGE = 300

export const tunaSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchTunaEditorialNotes.pending, state => {
        state.editorsNotesStatus = 'loading'
      })
      .addCase(fetchTunaEditorialNotes.fulfilled, (state, action) => {
        state.editorsNotesStatus = 'succeeded'
        state.editorsNotes = action.payload?.editorsNotes
        state.faq = action.payload?.faq
      })
      .addCase(fetchTunaEditorialNotes.rejected, (state, action) => {
        state.editorsNotesStatus = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchTunaResults.pending, state => {
        state.resultsStatus = 'loading'
      })
      .addCase(fetchTunaResults.fulfilled, (state, action) => {
        state.resultsStatus = 'succeeded'
        state.resultsData = action.payload?.data
      })
      .addCase(fetchTunaResults.rejected, (state, action) => {
        state.resultsStatus = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchTunaBrowse.pending, state => {
        state.tcomBrowseStatus = 'loading'
      })
      .addCase(fetchTunaBrowse.fulfilled, (state, action) => {
        state.tcomBrowseStatus = 'succeeded'
        state.tcomBrowseList = action.payload?.data
        state.tcomTotalResults = action.payload?.meta?.totalResults
      })
      .addCase(fetchTunaBrowse.rejected, (state, action) => {
        state.tcomBrowseStatus = 'failed'
        state.tcomBrowseError = action.error.message
      })
  },
  initialState,
  name: 'tuna',
  reducers: {
    resetTunaSlice: () => initialState
  }
})

export const actions = tunaSlice.actions

export default tunaSlice.reducer
