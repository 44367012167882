// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wcf3kq034KQKveGEe5Nl{background-color:unset;display:flex;list-style-type:none;margin-bottom:18px;padding-left:0}.wcf3kq034KQKveGEe5Nl li{margin-right:24px}.wcf3kq034KQKveGEe5Nl li:last-child{margin-right:0}.wcf3kq034KQKveGEe5Nl a:link,.wcf3kq034KQKveGEe5Nl a:visited{color:#fff}.wcf3kq034KQKveGEe5Nl a:hover,.wcf3kq034KQKveGEe5Nl a:active{color:var(--softgray)}.fWLhYiQTzUNtYWvgjvLw{color:currentcolor;height:24px;width:auto}@media(min-width: 1080px){.fWLhYiQTzUNtYWvgjvLw{height:32px;width:auto}}`, "",{"version":3,"sources":["webpack://./src/client/components/exportable/SocialLinks/SocialLinks.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,cAAA,CAEA,yBACE,iBAAA,CAEA,oCACE,cAAA,CAKF,6DAEE,UAAA,CAGF,6DAEE,qBAAA,CAKN,sBACE,kBAAA,CACA,WAAA,CACA,UAAA,CAGF,0BACE,sBACE,WAAA,CACA,UAAA,CAAA","sourcesContent":[".root {\n  background-color: unset;\n  display: flex;\n  list-style-type: none;\n  margin-bottom: 18px;\n  padding-left: 0;\n\n  li {\n    margin-right: 24px;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n\n  a {\n    &:link,\n    &:visited {\n      color: #fff;\n    }\n\n    &:hover,\n    &:active {\n      color: var(--softgray);\n    }\n  }\n}\n\n.social {\n  color: currentcolor;\n  height: 24px;\n  width: auto;\n}\n\n@media (min-width: 1080px) {\n  .social {\n    height: 32px;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `wcf3kq034KQKveGEe5Nl`,
	"social": `fWLhYiQTzUNtYWvgjvLw`
};
module.exports = ___CSS_LOADER_EXPORT___;
