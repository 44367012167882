import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './SubjectHeading.module.scss'

const SubjectHeading = ({ className, text, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <h2 className={classNames} {...props}>
      {text}
    </h2>
  )
}

SubjectHeading.propTypes = {
  className: string,
  text: string.isRequired
}

SubjectHeading.defaultProps = {
  className: null
}

export default withStyles(styles)(SubjectHeading)
