import React from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Link } from 'react-router-dom'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './PagingListItem.module.scss'

export const PagingListItem = ({
  children,
  className,
  ellipses,
  href,
  square
}) => {
  const classNames = cx(styles.root, className, {
    [styles.square]: square,
    [styles.ellipses]: ellipses
  })

  const content = href ? (
    <Link to={href}>{children}</Link>
  ) : (
    <span>{children}</span>
  )

  return (
    <li className={classNames} data-type="paging-list-item">
      {content}
    </li>
  )
}

PagingListItem.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  ellipses: bool,
  href: string,
  square: bool
}

PagingListItem.defaultProps = {
  className: null,
  ellipses: null,
  href: null,
  square: null
}

export default withStyles(styles)(PagingListItem)
