import { capitalize } from '~utils/string'

const addSearchToDataLayer = ({ action, searchData = {} }) => {
  let eventData
  const actionTypes = ['completed', 'initiated']

  if (action === 'searched') {
    eventData = {
      event: 'Content Listing Displayed',
      listingDisplayed: {
        ...searchData
      }
    }
  } else if (action === 'abandoned') {
    eventData = {
      event: `Onsite Search ${capitalize(action)}`,
      onsiteSearch: {
        searchTerm: searchData.clickedTerm,
        status: action
      }
    }
  } else if (actionTypes.includes(action)) {
    eventData = {
      event: `Onsite Search ${capitalize(action)}`,
      onsiteSearch: {
        status: action
      }
    }
  } else {
    return null
  }

  window.appEventData = window.appEventData || []
  window.appEventData.push(eventData)
}

export default addSearchToDataLayer
