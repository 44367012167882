// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bQ4m3UDSsC4_SprIf7mw{background-color:#fff;padding:20px;width:100%}.bQ4m3UDSsC4_SprIf7mw~.bQ4m3UDSsC4_SprIf7mw{margin-top:20px}@media(min-width: 480px){.bQ4m3UDSsC4_SprIf7mw{padding:30px}}@media(min-width: 768px){.bQ4m3UDSsC4_SprIf7mw{padding:35px 40px}}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/ResultsCard/ResultsCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CACA,UAAA,CAEA,4CACE,eAAA,CAIJ,yBACE,sBACE,YAAA,CAAA,CAIJ,yBACE,sBACE,iBAAA,CAAA","sourcesContent":[".root {\n  background-color: #fff;\n  padding: 20px;\n  width: 100%;\n\n  ~ .root {\n    margin-top: 20px;\n  }\n}\n\n@media (min-width: 480px) {\n  .root {\n    padding: 30px;\n  }\n}\n\n@media (min-width: 768px) {\n  .root {\n    padding: 35px 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `bQ4m3UDSsC4_SprIf7mw`
};
module.exports = ___CSS_LOADER_EXPORT___;
