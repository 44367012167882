import React from 'react'
import { object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import A from '~elements/A/A'
import { LINKID_PUBLISHERS } from '~utils/analytics/linkIds'
import formatDate from '~utils/formatDate'
import { emphasizeAndParse } from '~utils/parseHtml'
import {
  GUTENBERG,
  NATURAL_LANGUAGE_PROCESSING,
  THE_DAILY_BEAST
} from '~utils/publisher'
import styles from './ExampleSentence.module.scss'

const ExampleSentence = ({ sentence, source, word }) => {
  const {
    abbreviation,
    author,
    publication_date: publicationDate,
    sourceName,
    title,
    url
  } = source
  let formattedSourceName
  let linkId
  let displayedUrl = url
  const formattedDate = publicationDate && formatDate(publicationDate)

  if (abbreviation === THE_DAILY_BEAST) {
    formattedSourceName = 'THE DAILY BEAST'
    linkId = LINKID_PUBLISHERS.dailyBeast
    displayedUrl = `${url}?source=dictionary`
  } else if (abbreviation === NATURAL_LANGUAGE_PROCESSING) {
    linkId = LINKID_PUBLISHERS.nlp
    formattedSourceName = sourceName
  } else if (abbreviation === GUTENBERG) {
    linkId = LINKID_PUBLISHERS.gutenberg
  }

  return (
    <li className={styles.root}>
      <p>{emphasizeAndParse(sentence, word)}</p>
      <div>
        <span>
          <A data-linkid={linkId} href={displayedUrl}>
            {title}
          </A>
        </span>
        <span> | {author}</span>
        {formattedDate && <span> | {formattedDate}</span>}
        {formattedSourceName && (
          <span>
            <A href={displayedUrl}> | {formattedSourceName}</A>
          </span>
        )}
      </div>
    </li>
  )
}

ExampleSentence.propTypes = {
  sentence: string.isRequired,
  source: object.isRequired,
  word: string.isRequired
}

export default withStyles(styles)(ExampleSentence)
