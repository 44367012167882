// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YommMxopETPCP_wzxPxE{margin-bottom:24px}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/ResultsPageModuleWrapper/ResultsPageModuleWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".root {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `YommMxopETPCP_wzxPxE`
};
module.exports = ___CSS_LOADER_EXPORT___;
