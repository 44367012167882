import { createSlice } from '@reduxjs/toolkit'
import {
  fetchFeaturedLearningHubQuizzes,
  fetchFeaturedLearningHubWordLists,
  fetchLearningHubQuizzes,
  fetchLearningHubWordLists,
  fetchLearningHubWordListsTotal
} from './actions'
import initialState from './state.json'

export const learningHubSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchFeaturedLearningHubQuizzes.pending, state => {
        state.featuredQuizzes = { status: 'loading' }
      })
      .addCase(fetchFeaturedLearningHubQuizzes.fulfilled, (state, action) => {
        state.featuredQuizzes = {
          data: action.payload?.quizzes,
          status: 'success'
        }
      })
      .addCase(fetchFeaturedLearningHubQuizzes.rejected, (state, action) => {
        state.featuredQuizzes = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchLearningHubQuizzes.pending, state => {
        state.quizzes.status = 'loading'
      })
      .addCase(fetchLearningHubQuizzes.fulfilled, (state, action) => {
        const quizzes = action.payload?.quizzes
        state.quizzes.data.push(...quizzes)
        state.quizzes.status = 'success'
        state.quizzesTotal = action.payload?.meta.count
      })
      .addCase(fetchLearningHubQuizzes.rejected, (state, action) => {
        state.quizzes = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchFeaturedLearningHubWordLists.pending, state => {
        state.featuredWordLists = { status: 'loading' }
      })
      .addCase(fetchFeaturedLearningHubWordLists.fulfilled, (state, action) => {
        state.featuredWordLists = {
          data: action.payload?.data?.wordLists,
          status: 'success'
        }
      })
      .addCase(fetchFeaturedLearningHubWordLists.rejected, (state, action) => {
        state.featuredWordLists = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchLearningHubWordLists.pending, state => {
        state.wordLists.status = 'loading'
      })
      .addCase(fetchLearningHubWordLists.fulfilled, (state, action) => {
        const wordLists = action.payload?.data?.wordLists
        state.wordLists.data.push(...wordLists)
        state.wordLists.status = 'success'
      })
      .addCase(fetchLearningHubWordLists.rejected, (state, action) => {
        state.wordLists = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchLearningHubWordListsTotal.pending, state => {
        state.wordListsTotal.status = 'loading'
      })
      .addCase(fetchLearningHubWordListsTotal.fulfilled, (state, action) => {
        state.wordListsTotal = {
          data: action.payload?.data?.wordListsAggregate?.totalCount,
          status: 'success'
        }
      })
      .addCase(fetchLearningHubWordListsTotal.rejected, (state, action) => {
        state.wordListsTotal = {
          error: action.error.message,
          status: 'failed'
        }
      })
  },
  initialState,
  name: 'learningHub',
  reducers: {
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload
      state.wordLists.data = []
      state.wordLists.status = 'loading'
      state.quizzes.data = []
      state.quizzes.status = 'loading'
    },
    setSelectedSort: (state, { payload }) => {
      state.selectedSort = payload
      state.wordLists.data = []
      state.wordLists.status = 'loading'
    }
  }
})

export const actions = learningHubSlice.actions

export default learningHubSlice.reducer
