// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iVXFmgsyc0xdR8f80KbO{position:absolute;width:100%}.XO34hP1sPaowZQjTnpKH{color:var(--darkgraytext);margin:-20px auto;padding:8px;width:max-content}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/Notification/Notification.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CAGF,sBACE,yBAAA,CACA,iBAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".root {\n  position: absolute;\n  width: 100%;\n}\n\n.container {\n  color: var(--darkgraytext);\n  margin: -20px auto;\n  padding: 8px;\n  width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `iVXFmgsyc0xdR8f80KbO`,
	"container": `XO34hP1sPaowZQjTnpKH`
};
module.exports = ___CSS_LOADER_EXPORT___;
