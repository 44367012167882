// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.mneB08iqKHCurEOroxuc{cursor:pointer;transition:all .25s ease-in-out 0s}a.mneB08iqKHCurEOroxuc:link,a.mneB08iqKHCurEOroxuc:visited,a.mneB08iqKHCurEOroxuc:hover,a.mneB08iqKHCurEOroxuc:active{color:var(--aard-color-ink-button-onenabled)}a.mneB08iqKHCurEOroxuc:hover{text-decoration:none;transform:scale(1.05) translate3d(0, 0, 0)}a.mneB08iqKHCurEOroxuc,span.mneB08iqKHCurEOroxuc{align-items:center;border-radius:100px;display:inline-flex;font-family:Arial,sans-serif;min-height:32px;padding:6px 16px;position:relative;text-align:left;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/Pill/Pill.module.scss"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,kCAAA,CAEA,sHAIE,4CAAA,CAGF,6BACE,oBAAA,CACA,0CAAA,CAIJ,iDAEE,kBAAA,CACA,mBAAA,CACA,mBAAA,CACA,4BAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,oBAAA","sourcesContent":["a.root {\n  cursor: pointer;\n  transition: all 0.25s ease-in-out 0s;\n\n  &:link,\n  &:visited,\n  &:hover,\n  &:active {\n    color: var(--aard-color-ink-button-onenabled);\n  }\n\n  &:hover {\n    text-decoration: none;\n    transform: scale(1.05) translate3d(0, 0, 0);\n  }\n}\n\na.root,\nspan.root {\n  align-items: center;\n  border-radius: 100px;\n  display: inline-flex;\n  font-family: Arial, sans-serif;\n  min-height: 32px;\n  padding: 6px 16px;\n  position: relative;\n  text-align: left;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `mneB08iqKHCurEOroxuc`
};
module.exports = ___CSS_LOADER_EXPORT___;
