import React from 'react'
import { arrayOf, bool, number, object, string } from 'prop-types'
import { A } from '~elements'
import styles from './MobileNavigationDrawer.module.scss'

const SubMenuItems = ({ isExpanded, linkModulePosition, linkName, subMenu }) =>
  subMenu.map(
    (
      { hasTrademark, linkName: subMenuLinkName, name, url },
      subMenuItemIndex
    ) => (
      <React.Fragment key={`li-${name}`}>
        <li className={styles['menu-link']}>
          <A
            data-analytics="aa-track"
            data-linkmodule="sub-navigation"
            data-linkmodule_position={`${linkModulePosition}.${
              subMenuItemIndex + 1
            }`}
            data-linkname={`${linkName}:${subMenuLinkName}`}
            data-linktype="cta"
            data-linkurl={url}
            data-location="header"
            data-type="pages-menu"
            href={url}
            tabIndex={isExpanded ? 0 : -1}
          >
            {name}
            {hasTrademark && '™'}
          </A>
        </li>
      </React.Fragment>
    )
  )

SubMenuItems.propTypes = {
  isExpanded: bool.isRequired,
  linkModulePosition: number.isRequired,
  linkName: string.isRequired,
  subMenu: arrayOf(object)
}

SubMenuItems.defaultProps = {
  subMenu: []
}

export default SubMenuItems
