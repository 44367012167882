import React, { useState } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DICTIONARY_URL } from '~client/constants'
import { A, UpRightFromSquareIcon } from '~elements'
import { siteMenuData } from '~utils/siteMenuData'
import styles from './PagesMenu.module.scss'

export const PagesMenu = ({ className, dictionaryUrl }) => {
  const classNames = cx(styles.root, className)
  const [activeTabId, setActiveTabId] = useState('')

  const menuData = [...siteMenuData(dictionaryUrl)]

  const closeTab = () => setActiveTabId(null)

  const menuListItems = menuData.map(
    ({ component, linkName, name, subMenu, url }, index) => {
      const id = `pagesmenu-tab-${linkName}`
      const isActiveTab = id === activeTabId
      const isMenuOpen = subMenu.length > 0 && isActiveTab
      const linkModulePosition = index + 1

      const tabClassNames = cx(styles['nav-tab'], {
        [styles['is-open']]: !component && isActiveTab
      })

      const linkUrl = url

      const openTab = () => setActiveTabId(id)

      if (component) return null

      const subMenuListItems = subMenu.map(
        (
          {
            hasTrademark,
            isExternalLink,
            linkName: subMenuLinkName,
            name,
            url
          },
          subMenuItemIndex
        ) => {
          const linkUrl = url

          return (
            <li key={`li-${name}`}>
              <A
                data-analytics="aa-track"
                data-linkmodule="sub-navigation"
                data-linkmodule_position={`${linkModulePosition}.${
                  subMenuItemIndex + 1
                }`}
                data-linkname={`${linkName}:${subMenuLinkName}`}
                data-linktype="cta"
                data-linkurl={linkUrl}
                data-location="header"
                data-type="pages-menu"
                href={linkUrl}
                target={isExternalLink ? '_blank' : '_self'}
              >
                {name}
                {hasTrademark && '™'}
                {isExternalLink && <UpRightFromSquareIcon />}
              </A>
            </li>
          )
        }
      )

      return (
        <li
          className={tabClassNames}
          data-title={name}
          key={`section-${linkName}`}
          onFocus={openTab}
          onMouseEnter={openTab}
        >
          <A
            data-analytics="aa-track"
            data-linkmodule="navigation"
            data-linkmodule_position={linkModulePosition}
            data-linkname={linkName}
            data-linktype="cta"
            data-linkurl={linkUrl}
            data-location="header"
            data-type="pages-menu"
            href={linkUrl}
            id={id}
          >
            {component || name}
          </A>
          {isMenuOpen && (
            <ul className={styles['nav-tab-content']}>{subMenuListItems}</ul>
          )}
        </li>
      )
    }
  )

  return (
    <ul
      aria-label="Pages Menu"
      className={classNames}
      data-type="pages-menu"
      onMouseLeave={closeTab}
    >
      {menuListItems}
    </ul>
  )
}

PagesMenu.propTypes = {
  className: string,
  dictionaryUrl: string
}

PagesMenu.defaultProps = {
  className: null,
  dictionaryUrl: DICTIONARY_URL
}

export default withStyles(styles)(PagesMenu)
