import React, { useEffect, useState } from 'react'
import { oneOf } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  A,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
  YouTubeIcon
} from '~elements'
import { DICTIONARY_SITENAME, THESAURUS_SITENAME } from '~store/location/slice'
import styles from './SocialLinks.module.scss'

const linkMap = [
  {
    dictionaryId: 'dictionarycom',
    icon: FacebookIcon,
    name: 'facebook',
    thesaurusId: 'thesauruscom',
    url: 'https://www.facebook.com/'
  },
  {
    dictionaryId: 'dictionarycom',
    icon: InstagramIcon,
    name: 'instagram',
    thesaurusId: 'thesaurus_com',
    url: 'https://www.instagram.com/'
  },
  {
    dictionaryId: 'dictionarycom',
    icon: TwitterIcon,
    name: 'twitter',
    thesaurusId: 'thesauruscom',
    url: 'https://www.twitter.com/'
  },
  {
    dictionaryId: 'company/dictionary.com/',
    icon: LinkedInIcon,
    name: 'linkedin',
    thesaurusId: 'company/dictionary.com/',
    url: 'https://www.linkedIn.com/'
  },
  {
    dictionaryId: 'DictionarycomVideo',
    icon: YouTubeIcon,
    name: 'youtube',
    thesaurusId: 'DictionarycomVideo',
    url: 'https://www.youtube.com/c/'
  }
]

export const SocialLinks = ({ selectedSite }) => {
  const [links, setLinks] = useState([])

  useEffect(() => {
    const idKey =
      selectedSite === THESAURUS_SITENAME ? 'thesaurusId' : 'dictionaryId'

    setLinks(
      linkMap.map(({ icon: Icon, name, url, ...ids }) => {
        const id = ids[idKey]

        if (!id) return null
        const href = `${url}${id}`

        return (
          <li key={name}>
            <A
              data-analytics="aa-track"
              data-linklocation="footer"
              data-linkmodule="social"
              data-linkname={name}
              data-linktype="cta"
              data-linkurl={href}
              data-type="social"
              href={href}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <Icon className={styles.social} title={name} />
            </A>
          </li>
        )
      })
    )
  }, [selectedSite])

  return (
    <ul className={styles.root} data-type="social-links">
      {links}
    </ul>
  )
}

SocialLinks.propTypes = {
  selectedSite: oneOf([DICTIONARY_SITENAME, THESAURUS_SITENAME]).isRequired
}

export default withStyles(styles)(SocialLinks)
