import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './HorizontalRuleTitle.module.scss'

const HorizontalRuleTitle = ({ children }) => {
  return (
    <div
      className={styles['horizontal-rule-title']}
      data-type="horizontal-rule-title"
    >
      {children}
    </div>
  )
}

HorizontalRuleTitle.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired
}

export default withStyles(styles)(HorizontalRuleTitle)
