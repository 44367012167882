// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H_J03lT8Xbt3Vo5vSxPe:link,.H_J03lT8Xbt3Vo5vSxPe:visited{color:var(--aard-color-ink-default);text-decoration:none}.H_J03lT8Xbt3Vo5vSxPe:hover{color:var(--aard-color-interactive-headline-hovered-ondefault)}.H_J03lT8Xbt3Vo5vSxPe:active{color:var(--aard-color-interactive-headline-active-ondefault)}.fPmUJzMRG_3eYtzPMP9g:link,.fPmUJzMRG_3eYtzPMP9g:visited{color:var(--aard-color-ink-default)}.fPmUJzMRG_3eYtzPMP9g:hover{color:var(--aard-color-interactive-headline-hovered-onmuted)}.fPmUJzMRG_3eYtzPMP9g:active{color:var(--aard-color-interactive-headline-active-onmuted)}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/HeadlineLink/HeadlineLink.module.scss"],"names":[],"mappings":"AACE,yDAEE,mCAAA,CACA,oBAAA,CAGF,4BACE,8DAAA,CAGF,6BACE,6DAAA,CAKF,yDAEE,mCAAA,CAGF,4BACE,4DAAA,CAGF,6BACE,2DAAA","sourcesContent":[".root {\n  &:link,\n  &:visited {\n    color: var(--aard-color-ink-default);\n    text-decoration: none;\n  }\n\n  &:hover {\n    color: var(--aard-color-interactive-headline-hovered-ondefault);\n  }\n\n  &:active {\n    color: var(--aard-color-interactive-headline-active-ondefault);\n  }\n}\n\n.color {\n  &:link,\n  &:visited {\n    color: var(--aard-color-ink-default);\n  }\n\n  &:hover {\n    color: var(--aard-color-interactive-headline-hovered-onmuted);\n  }\n\n  &:active {\n    color: var(--aard-color-interactive-headline-active-onmuted);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `H_J03lT8Xbt3Vo5vSxPe`,
	"color": `fPmUJzMRG_3eYtzPMP9g`
};
module.exports = ___CSS_LOADER_EXPORT___;
