import isObjectWithProperties from '~utils/isObjectWithProperties'

/**
 * Simulate a link click for Adobe analytics
 * @param {Event} event - Event emitted from calling method
 * @param {Object} dataset - Analytics override object sourced from DOM element data attributes.
 */
const forceClickEvent = (event, dataset = {}) => {
  if (!document || !event?.target) {
    return
  }

  const linkData = {
    analytics: 'aa-track',
    linklocation: 'body',
    linkmodule: 'click-tracking-helper',
    linkname: 'click-tracking',
    linktype: 'link',
    linkurl: document.location.toString()
  }

  if (isObjectWithProperties(dataset)) {
    Object.assign(linkData, dataset)
  }

  const tag = document.createElement('a')

  tag.href = 'javascript:void("Click Tracking")'

  Object.keys(linkData).forEach(key => {
    tag.dataset[key] = linkData[key]
  })

  tag.addEventListener('click', ({ target }) => {
    target.parentElement.removeChild(target)
  })

  const { style } = tag
  style.opacity = 0
  style.position = 'absolute'
  style.pointerEvents = 'none'

  event.target.parentElement.appendChild(tag)

  tag.dispatchEvent(new MouseEvent('click'))
}

export default forceClickEvent
