// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hKc5_7ESpepHS_34gUUw{fill:currentcolor;min-height:1rem}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/IconLibrary/IconLibrary.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA","sourcesContent":[".root {\n  fill: currentcolor;\n  min-height: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `hKc5_7ESpepHS_34gUUw`
};
module.exports = ___CSS_LOADER_EXPORT___;
