// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ei4YYrvVr8otdIp1PIWM{background-color:#fff;border-radius:3px;color:var(--darkgraytext);display:block;filter:drop-shadow(0 2px 8px var(--mildgray));font-size:16px;height:fit-content;line-height:1.5;padding:12px 16px;will-change:filter}.Ei4YYrvVr8otdIp1PIWM::before{border:10px solid rgba(0,0,0,0);border-top-color:#fff;content:" ";height:0;left:50%;margin-left:-10px;pointer-events:none;position:absolute;top:100%;width:0}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,iBAAA,CACA,yBAAA,CACA,aAAA,CACA,6CAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CAEA,8BACE,+BAAA,CACA,qBAAA,CACA,WAAA,CACA,QAAA,CACA,QAAA,CACA,iBAAA,CACA,mBAAA,CACA,iBAAA,CACA,QAAA,CACA,OAAA","sourcesContent":[".root {\n  background-color: #fff;\n  border-radius: 3px;\n  color: var(--darkgraytext);\n  display: block;\n  filter: drop-shadow(0 2px 8px var(--mildgray));\n  font-size: 16px;\n  height: fit-content;\n  line-height: 1.5;\n  padding: 12px 16px;\n  will-change: filter;\n\n  &::before {\n    border: 10px solid transparent;\n    border-top-color: #fff;\n    content: ' ';\n    height: 0;\n    left: 50%;\n    margin-left: -10px;\n    pointer-events: none;\n    position: absolute;\n    top: 100%;\n    width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Ei4YYrvVr8otdIp1PIWM`
};
module.exports = ___CSS_LOADER_EXPORT___;
