import { createSlice } from '@reduxjs/toolkit'
import { fetchPageData } from './actions'
import initialState from './state.json'

export const pageSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchPageData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPageData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.content = action.payload?.story?.content
      })
      .addCase(fetchPageData.rejected, (state, action) => {
        state.error = action.error.message
        state.status = 'failed'
      })
  },
  initialState,
  name: 'page',
  reducers: {
    resetPageSlice: state => {
      state.content = initialState.content
      state.status = initialState.status
    }
  }
})

export const actions = pageSlice.actions

export default pageSlice.reducer
