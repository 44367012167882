// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TSqnrw0qSMGseOGGBuxc:link,.TSqnrw0qSMGseOGGBuxc:visited{color:var(--aard-color-interactive-link-default);text-decoration:underline}.TSqnrw0qSMGseOGGBuxc:hover{color:var(--aard-color-interactive-link-hovered)}.TSqnrw0qSMGseOGGBuxc:active{color:var(--aard-color-interactive-link-active)}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/DefaultLink/DefaultLink.module.scss"],"names":[],"mappings":"AACE,yDAEE,gDAAA,CACA,yBAAA,CAGF,4BACE,gDAAA,CAGF,6BACE,+CAAA","sourcesContent":[".root {\n  &:link,\n  &:visited {\n    color: var(--aard-color-interactive-link-default);\n    text-decoration: underline;\n  }\n\n  &:hover {\n    color: var(--aard-color-interactive-link-hovered);\n  }\n\n  &:active {\n    color: var(--aard-color-interactive-link-active);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TSqnrw0qSMGseOGGBuxc`
};
module.exports = ___CSS_LOADER_EXPORT___;
