import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'

const GRAPHQL_ENDPOINT = process.env.SAFE_GRAPHQL_ENDPOINT

const parseGraphQLResponse = async response => {
  const data = await response.json()
  if (data.errors) {
    throw new Error(data.errors[0]?.message)
  }
  return data
}

const GET_WORD_LISTS = `
  query WordLists(
    $category: StringOperationExpression
    $createdAtMax: DateTime
    $id: IDOperationExpression
    $isApprovedEditor: BooleanOperationExpression
    $limit: Int
    $offset: Int
    $orderCreatedAt: OrderBy
    $orderName: OrderBy
    $shortId: IDOperationExpression
  ) {
    wordLists(
      limit: $limit
      offset: $offset
      orderBy: { createdAt: $orderCreatedAt, name: $orderName }
      where: {
        category: $category
        createdAt: { _lte: $createdAtMax }
        id: $id
        shortId: $shortId
        user: { isApprovedEditor: $isApprovedEditor }
      }
    ) {
      category
      color
      createdAt
      description
      id
      name
      shortId
      user {
        isApprovedEditor
      }
      words {
        displayForm
      }
    }
  }
`

const GET_WORD_LISTS_AGGREGATE = `
  query WordListsAggregate(
    $category: StringOperationExpression
    $createdAtMax: DateTime
    $id: IDOperationExpression
    $isApprovedEditor: BooleanOperationExpression
    $shortId: IDOperationExpression
  ) {
    wordListsAggregate(
      where: {
        category: $category
        createdAt: { _lte: $createdAtMax }
        id: $id
        shortId: $shortId
        user: { isApprovedEditor: $isApprovedEditor }
      }
    ) {
      totalCount
    }
  }
`

export const fetchFeaturedLearningHubWordLists = createAsyncThunk(
  'fetchFeaturedLearningHubWordLists',
  async (
    { createdAtMax, isApprovedEditor, limit, orderCreatedAt },
    { getState }
  ) => {
    const {
      learningHub: {
        selectedCategory: { categoryTitle: category }
      }
    } = getState()
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: GET_WORD_LISTS,
        variables: {
          category: {
            _eq: category ?? undefined
          },
          createdAtMax,
          isApprovedEditor: {
            _eq: isApprovedEditor
          },
          limit,
          orderCreatedAt
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const fetchLearningHubWordLists = createAsyncThunk(
  'fetchLearningHubWordLists',
  async (
    { createdAtMax, ids, isApprovedEditor, limit, offset },
    { getState }
  ) => {
    const {
      learningHub: {
        selectedCategory: { categoryTitle: category },
        selectedSort
      }
    } = getState()
    const variables = {
      category: {
        _eq: category ?? undefined
      },
      createdAtMax,
      id: {
        _nin: ids
      },
      isApprovedEditor: {
        _eq: isApprovedEditor
      },
      limit,
      offset
    }
    if (selectedSort) {
      const [sortKey, sortVal] = selectedSort.split('.')
      variables[sortKey] = sortVal
    }

    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: GET_WORD_LISTS,
        variables
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const fetchLearningHubWordListsTotal = createAsyncThunk(
  'fetchLearningHubWordListsTotal',
  async ({ createdAtMax, limit, offset, orderCreatedAt }, { getState }) => {
    const {
      learningHub: {
        selectedCategory: { categoryTitle: category }
      }
    } = getState()
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: GET_WORD_LISTS_AGGREGATE,
        variables: {
          category: {
            _eq: category ?? undefined
          },
          createdAtMax,
          isApprovedEditor: {
            _eq: true
          },
          limit,
          offset,
          orderCreatedAt
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const fetchFeaturedLearningHubQuizzes = createAsyncThunk(
  'fetchFeaturedLearningHubQuizzes',
  async ({ limit }, { getState }) => {
    const {
      learningHub: {
        selectedCategory: { categorySlug: category }
      }
    } = getState()
    const endpointUrl = process.env.SAFE_WORDPRESS_QUIZZES_ENDPOINT
    const params = new URLSearchParams({ limit })
    if (category) params.append('category', category)
    const endpointWithParams = `${endpointUrl}?${params}`
    const response = await fetch(endpointWithParams)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const fetchLearningHubQuizzes = createAsyncThunk(
  'fetchLearningHubQuizzes',
  async ({ limit, offset }, { getState }) => {
    const {
      learningHub: {
        selectedCategory: { categorySlug: category }
      }
    } = getState()
    const endpointUrl = process.env.SAFE_WORDPRESS_QUIZZES_ENDPOINT
    const params = new URLSearchParams({ limit, offset })
    if (category) params.append('category', category)
    const endpointWithParams = `${endpointUrl}?${params}`
    const response = await fetch(endpointWithParams)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)
