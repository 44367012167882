import React from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import WunderkindContainer from '~composites/WunderkindContainer/WunderkindContainer'
import styles from './ResultsCard.module.scss'

const ResultsCard = ({ children, className, hideWunderkindAd, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <>
      {!hideWunderkindAd && <WunderkindContainer />}
      <section className={classNames} {...props}>
        {children}
      </section>
    </>
  )
}

ResultsCard.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  hideWunderkindAd: bool
}

ResultsCard.defaultProps = {
  className: null,
  hideWunderkindAd: false
}

export default withStyles(styles)(ResultsCard)
