import React from 'react'
import { number } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './ProgressBar.module.scss'

const ProgressBar = ({ current, total }) => {
  return (
    <div className={styles.root} data-type="progress-bar">
      <progress className={styles.progress} max={total} value={current} />
      <p>
        {current}/{total}
      </p>
    </div>
  )
}

ProgressBar.propTypes = {
  current: number,
  total: number.isRequired
}

ProgressBar.defaultProps = {
  current: 0
}

export default withStyles(styles)(ProgressBar)
