import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import A from '~elements/A/A'
import styles from './Pill.module.scss'

const Pill = ({ children, className, href, linkId, ...props }) => {
  const classNames = cx(styles.root, className)

  const pill =
    href !== '#' ? (
      <A
        className={classNames}
        data-type="pill-button"
        href={href}
        linkId={linkId}
        {...props}
      >
        {children}
      </A>
    ) : (
      <span className={classNames}>{children}</span>
    )

  return pill
}

Pill.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  href: string.isRequired,
  linkId: string
}

Pill.defaultProps = {
  className: null,
  linkId: null
}

export default withStyles(styles)(Pill)
