import React, { useMemo } from 'react'
import StyleContext from 'isomorphic-style-loader/StyleContext'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { createStore } from '~store'

export const store = createStore()
const Export = ({ children }) => {
  const insertCss = (...styles) => {
    const removeCss = styles.map(style => {
      if (typeof style._insertCss === 'function') return style._insertCss()
      return null
    })
    return () => removeCss.forEach(dispose => dispose())
  }

  const styleContext = { insertCss: useMemo(() => insertCss, [insertCss]) }

  return (
    <BrowserRouter>
      <StyleContext.Provider value={styleContext}>
        <Provider store={store}>{children}</Provider>
      </StyleContext.Provider>
    </BrowserRouter>
  )
}

Export.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired
}

export default Export
