import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './ResultsPageModuleWrapper.module.scss'

export const ResultsPageModuleWrapper = ({ children, className }) => {
  const classNames = cx(styles.root, className)

  return <div className={classNames}>{children}</div>
}

ResultsPageModuleWrapper.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string
}

ResultsPageModuleWrapper.defaultProps = {
  className: null
}

export default withStyles(styles)(ResultsPageModuleWrapper)
