import React from 'react'
import { element, number, shape, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './IconLibrary.module.scss'

const HtmlComment = ({ comment }) => {
  const hasComment = typeof comment === 'string'
  const callback = instance => {
    if (instance?.parentNode) {
      instance.outerHTML = comment
    }
  }

  return (
    hasComment && (
      <script
        dangerouslySetInnerHTML={{ __html: comment }}
        ref={callback}
        type="text/comment"
      />
    )
  )
}

const FONT_AWESOME_DISCLAIMER =
  '<!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->'

const withSvg = ({ children, comment, height, viewBox, width }) =>
  function withSvg({ className, title, ...props }) {
    const classNames = cx(styles.root, className)

    return (
      <svg
        className={classNames}
        height={height}
        role={title ? 'img' : undefined}
        viewBox={viewBox}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        {title && <title>{title}</title>}
        {comment && <HtmlComment comment={comment} />}
        {children}
      </svg>
    )
  }

export const ArrowDropdownIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M3.82818 7.42424L9.28834 13.7961C9.37635 13.8988 9.48553 13.9812 9.60838 14.0377C9.73123 14.0942 9.86484 14.1234 10.0001 14.1234C10.1353 14.1234 10.2689 14.0942 10.3917 14.0377C10.5146 13.9812 10.6238 13.8988 10.7118 13.7961L16.1719 7.42424C16.693 6.81604 16.261 5.87659 15.4602 5.87659H4.53834C3.73756 5.87659 3.30553 6.81604 3.82818 7.42424Z"
        fill="#252528"
      />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 20,
    viewBox: '0 0 20 20',
    width: 20
  })
)

export const ArrowTopRightIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        clipRule="evenodd"
        d="M19.0001 6.41421L5.70718 19.7071L4.29297 18.2929L17.5859 5H10.0001V3H21.0001V14H19.0001V6.41421Z"
        fill="black"
        fillRule="evenodd"
      />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 24,
    viewBox: '0 0 24 24',
    width: 24
  })
)

export const BarsIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 27,
    viewBox: '0 0 448 512',
    width: 24
  })
)

export const BookIcon = withStyles(styles)(
  withSvg({
    children: (
      <>
        <path
          d="M12.825 2.05897C10.3552 0.879767 6.90303 0.30213 2.28125 0.281622C1.84537 0.2757 1.41804 0.402802 1.05625 0.645978C0.759292 0.846701 0.516204 1.11732 0.348371 1.43403C0.180538 1.75075 0.0931017 2.10385 0.0937536 2.46229V22.0199C0.0937536 23.342 1.03438 24.3393 2.28125 24.3393C7.13955 24.3393 12.0129 24.7932 14.9318 27.5522C14.9718 27.5901 15.022 27.6155 15.0762 27.6251C15.1304 27.6347 15.1862 27.6282 15.2368 27.6064C15.2873 27.5845 15.3303 27.5483 15.3605 27.5022C15.3906 27.4561 15.4065 27.4022 15.4063 27.3471V4.30252C15.4064 4.14704 15.3731 3.99335 15.3087 3.85183C15.2443 3.71031 15.1503 3.58426 15.033 3.48221C14.3644 2.91061 13.6217 2.43187 12.825 2.05897Z"
          fill="#BED9FF"
        />
        <path
          d="M31.9438 0.643927C31.5818 0.401356 31.1544 0.274964 30.7188 0.281622C26.097 0.30213 22.6448 0.877032 20.175 2.05897C19.3784 2.4312 18.6355 2.90899 17.9663 3.47947C17.8493 3.58171 17.7555 3.7078 17.6912 3.84929C17.627 3.99078 17.5937 4.14439 17.5938 4.29979V27.3458C17.5937 27.3987 17.6093 27.4504 17.6386 27.4945C17.6678 27.5386 17.7095 27.5731 17.7582 27.5936C17.807 27.6141 17.8608 27.6198 17.9127 27.6098C17.9647 27.5999 18.0126 27.5749 18.0504 27.5379C19.8052 25.7947 22.8848 24.3373 30.7215 24.338C31.3016 24.338 31.858 24.1075 32.2683 23.6973C32.6785 23.287 32.909 22.7306 32.909 22.1505V2.46297C32.9098 2.10383 32.8221 1.75001 32.6538 1.43276C32.4855 1.11552 32.2416 0.844598 31.9438 0.643927Z"
          fill="#BED9FF"
        />
      </>
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 28,
    viewBox: '0 0 33 28',
    width: 33
  })
)

export const BriefcaseIcon = withStyles(styles)(
  withSvg({
    children: (
      <>
        <path
          d="M32.9062 9.03125C32.905 7.87132 32.4436 6.75925 31.6234 5.93906C30.8032 5.11886 29.6912 4.65752 28.5312 4.65625H25.25V3.5625C25.2491 2.69254 24.9031 1.85846 24.2879 1.24331C23.6728 0.628148 22.8387 0.282155 21.9688 0.28125H11.0312C10.1613 0.282155 9.32721 0.628148 8.71206 1.24331C8.0969 1.85846 7.75091 2.69254 7.75 3.5625V4.65625H4.46875C3.30882 4.65752 2.19675 5.11886 1.37656 5.93906C0.556359 6.75925 0.0950166 7.87132 0.09375 9.03125V12.3125H32.9062V9.03125ZM23.0625 4.65625H9.9375V3.5625C9.9375 3.27242 10.0527 2.99422 10.2579 2.7891C10.463 2.58398 10.7412 2.46875 11.0312 2.46875H21.9688C22.2588 2.46875 22.537 2.58398 22.7421 2.7891C22.9473 2.99422 23.0625 3.27242 23.0625 3.5625V4.65625Z"
          fill="#BED9FF"
        />
        <path
          d="M21.9688 15.0469C21.9688 15.482 21.7959 15.8993 21.4882 16.207C21.1805 16.5146 20.7632 16.6875 20.3281 16.6875H12.6719C12.2368 16.6875 11.8195 16.5146 11.5118 16.207C11.2041 15.8993 11.0312 15.482 11.0312 15.0469V14.7734C11.0312 14.7009 11.0024 14.6314 10.9512 14.5801C10.8999 14.5288 10.8303 14.5 10.7578 14.5H0.09375V24.3438C0.09375 25.5041 0.554686 26.6169 1.37516 27.4373C2.19563 28.2578 3.30843 28.7188 4.46875 28.7188H28.5312C29.6916 28.7188 30.8044 28.2578 31.6248 27.4373C32.4453 26.6169 32.9062 25.5041 32.9062 24.3438V14.5H22.2422C22.1697 14.5 22.1001 14.5288 22.0488 14.5801C21.9976 14.6314 21.9688 14.7009 21.9688 14.7734V15.0469Z"
          fill="#BED9FF"
        />
      </>
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 29,
    viewBox: '0 0 33 29',
    width: 33
  })
)

export const BulbIcon = withStyles(styles)(
  withSvg({
    children: (
      <>
        <path
          d="M13.687 30.7182H9.31204C9.02196 30.7182 8.74376 30.8334 8.53864 31.0385C8.33353 31.2436 8.21829 31.5218 8.21829 31.8119C8.21829 32.102 8.33353 32.3802 8.53864 32.5853C8.74376 32.7904 9.02196 32.9057 9.31204 32.9057H13.687C13.9771 32.9057 14.2553 32.7904 14.4604 32.5853C14.6656 32.3802 14.7808 32.102 14.7808 31.8119C14.7808 31.5218 14.6656 31.2436 14.4604 31.0385C14.2553 30.8334 13.9771 30.7182 13.687 30.7182Z"
          fill="#BED9FF"
        />
        <path
          d="M14.7808 27.4369H8.21829C7.92821 27.4369 7.65001 27.5522 7.44489 27.7573C7.23978 27.9624 7.12454 28.2406 7.12454 28.5307C7.12454 28.8208 7.23978 29.099 7.44489 29.3041C7.65001 29.5092 7.92821 29.6244 8.21829 29.6244H14.7808C15.0709 29.6244 15.3491 29.5092 15.5542 29.3041C15.7593 29.099 15.8745 28.8208 15.8745 28.5307C15.8745 28.2406 15.7593 27.9624 15.5542 27.7573C15.3491 27.5522 15.0709 27.4369 14.7808 27.4369Z"
          fill="#BED9FF"
        />
        <path
          d="M19.2529 3.28487C17.1973 1.22657 14.4438 0.0931706 11.4995 0.0931706C10.0622 0.0897449 8.63844 0.370313 7.30989 0.918761C5.98135 1.46721 4.77425 2.27273 3.75792 3.28905C2.7416 4.30538 1.93608 5.51248 1.38763 6.84102C0.839185 8.16957 0.558617 9.59338 0.562043 11.0307C0.562043 14.2176 1.78362 17.1987 3.91165 19.2092L4.2097 19.4888C5.44085 20.64 7.12454 22.217 7.12454 23.6088V25.2494C7.12454 25.5395 7.23978 25.8177 7.44489 26.0228C7.65001 26.2279 7.92821 26.3432 8.21829 26.3432H9.85892C10.004 26.3432 10.1431 26.2856 10.2456 26.183C10.3482 26.0804 10.4058 25.9413 10.4058 25.7963V17.7859C10.4058 17.6754 10.3723 17.5673 10.3097 17.4762C10.2471 17.385 10.1583 17.315 10.0551 17.2753C9.42353 17.0176 8.82353 16.6885 8.26683 16.2943C8.14172 16.2145 8.03419 16.1099 7.95078 15.9872C7.86736 15.8644 7.80981 15.7259 7.78163 15.5802C7.75345 15.4344 7.75523 15.2845 7.78686 15.1395C7.81849 14.9944 7.8793 14.8574 7.96561 14.7366C8.05191 14.6158 8.16189 14.5139 8.28885 14.437C8.41582 14.3601 8.5571 14.3098 8.70411 14.2893C8.85112 14.2687 9.00077 14.2783 9.14395 14.3175C9.28713 14.3566 9.42084 14.4245 9.53695 14.517C10.0428 14.8752 11.0169 15.4057 11.4995 15.4057C11.9822 15.4057 12.9563 14.8738 13.4635 14.517C13.6997 14.3685 13.9837 14.3159 14.2574 14.3699C14.5311 14.4239 14.7738 14.5804 14.9358 14.8075C15.0979 15.0345 15.1671 15.315 15.1293 15.5913C15.0914 15.8677 14.9494 16.1192 14.7323 16.2943C14.1755 16.6878 13.5755 17.0162 12.944 17.2733C12.8407 17.3129 12.752 17.383 12.6894 17.4741C12.6268 17.5653 12.5933 17.6733 12.5933 17.7839V25.7963C12.5933 25.9413 12.6509 26.0804 12.7535 26.183C12.856 26.2856 12.9951 26.3432 13.1402 26.3432H14.7808C15.0709 26.3432 15.3491 26.2279 15.5542 26.0228C15.7593 25.8177 15.8745 25.5395 15.8745 25.2494V23.6088C15.8745 22.2498 17.5398 20.6741 18.7559 19.523L19.0915 19.2044C21.2804 17.1147 22.437 14.2853 22.437 11.0307C22.4443 9.59324 22.1666 8.16862 21.6201 6.83913C21.0735 5.50963 20.269 4.30161 19.2529 3.28487Z"
          fill="#BED9FF"
        />
      </>
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 33,
    viewBox: '0 0 23 33',
    width: 23
  })
)

export const CheckIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 22,
    viewBox: '0 0 448 512',
    width: 22
  })
)

export const ChevronDownIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M7.41 8.58984L12 13.1698L16.59 8.58984L18 9.99984L12 15.9998L6 9.99984L7.41 8.58984Z"
        fill="#252528"
      />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 24,
    viewBox: '0 0 24 24',
    width: 24
  })
)

export const ChevronLeftIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M328 112L184 256l144 144"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="48"
      />
    ),
    height: 24,
    viewBox: '0 0 512 512',
    width: 24
  })
)

export const ChevronRightIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M184 112l144 144-144 144"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="48"
      />
    ),
    height: 24,
    viewBox: '0 0 512 512',
    width: 24
  })
)

export const CircleCheckIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 512 512',
    width: 32
  })
)

export const CircleInfoIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 20,
    viewBox: '0 0 512 512',
    width: 20
  })
)

export const CircleExclamationIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 20,
    viewBox: '0 0 512 512',
    width: 20
  })
)

export const CircleXIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 512 512',
    width: 32
  })
)

export const CloseIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M25.3334 8.54675L23.4534 6.66675L16.0001 14.1201L8.54675 6.66675L6.66675 8.54675L14.1201 16.0001L6.66675 23.4534L8.54675 25.3334L16.0001 17.8801L23.4534 25.3334L25.3334 23.4534L17.8801 16.0001L25.3334 8.54675Z"
        fill="#252528"
      />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 32 32',
    width: 32
  })
)

export const EllipsisVerticalIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 20,
    viewBox: '0 0 128 512',
    width: 20
  })
)

export const EnvelopeIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 512 512',
    width: 32
  })
)

export const FacebookIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 448 512',
    width: 32
  })
)

export const HeartIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M14.5008 26.625C14.0617 26.6244 13.6329 26.4918 13.2703 26.2442C7.89793 22.5973 5.57166 20.0967 4.28855 18.5333C1.55418 15.2008 0.245095 11.7794 0.282009 8.07432C0.325075 3.82852 3.73142 0.375 7.87537 0.375C10.8886 0.375 12.9757 2.07236 14.1911 3.48604C14.2296 3.53037 14.2772 3.56591 14.3306 3.59027C14.384 3.61463 14.442 3.62724 14.5008 3.62724C14.5595 3.62724 14.6175 3.61463 14.6709 3.59027C14.7244 3.56591 14.7719 3.53037 14.8104 3.48604C16.0259 2.071 18.1129 0.375 21.1261 0.375C25.2701 0.375 28.6764 3.82852 28.7195 8.075C28.7564 11.7808 27.446 15.2021 24.713 18.534C23.4299 20.0974 21.1036 22.5979 15.7312 26.2449C15.3685 26.4922 14.9398 26.6246 14.5008 26.625Z"
        fill="#BED9FF"
      />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 27,
    viewBox: '0 0 29 27',
    width: 29
  })
)

export const InstagramIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 448 512',
    width: 32
  })
)

export const LinkedInIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 448 512',
    width: 32
  })
)

export const ListIcon = withStyles(styles)(
  withSvg({
    children: (
      <>
        <path
          clipRule="evenodd"
          d="M9 9C9 8.44772 9.44772 8 10 8H28C28.5523 8 29 8.44772 29 9C29 9.55228 28.5523 10 28 10H10C9.44772 10 9 9.55228 9 9ZM9 16C9 15.4477 9.44772 15 10 15H28C28.5523 15 29 15.4477 29 16C29 16.5523 28.5523 17 28 17H10C9.44772 17 9 16.5523 9 16ZM9 23C9 22.4477 9.44772 22 10 22H28C28.5523 22 29 22.4477 29 23C29 23.5523 28.5523 24 28 24H10C9.44772 24 9 23.5523 9 23Z"
          fill="#252528"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M3 9C3 7.89543 3.89543 7 5 7C6.10457 7 7 7.89543 7 9C7 10.1046 6.10457 11 5 11C3.89543 11 3 10.1046 3 9Z"
          fill="#252528"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M3 16C3 14.8954 3.89543 14 5 14C6.10457 14 7 14.8954 7 16C7 17.1046 6.10457 18 5 18C3.89543 18 3 17.1046 3 16Z"
          fill="#252528"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M3 23C3 21.8954 3.89543 21 5 21C6.10457 21 7 21.8954 7 23C7 24.1046 6.10457 25 5 25C3.89543 25 3 24.1046 3 23Z"
          fill="#252528"
          fillRule="evenodd"
        />
      </>
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 32 32',
    width: 32
  })
)

export const MagnifyingGlassIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 28,
    viewBox: '0 0 512 512',
    width: 28
  })
)

export const MinusIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 16,
    viewBox: '0 0 448 512',
    width: 16
  })
)

export const PinterestIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 448 512',
    width: 32
  })
)

export const PlusIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 16,
    viewBox: '0 0 448 512',
    width: 16
  })
)

export const SchoolIcon = withStyles(styles)(
  withSvg({
    children: (
      <>
        <path
          d="M17.5003 25.1561C17.3099 25.156 17.1228 25.1063 16.9575 25.0118L7.3831 19.5397C7.2999 19.4916 7.20552 19.4664 7.10946 19.4664C7.01339 19.4664 6.91903 19.4918 6.83587 19.5399C6.75271 19.588 6.68369 19.6571 6.63575 19.7404C6.58781 19.8236 6.56264 19.918 6.56279 20.0141V25.1561C6.56262 25.3513 6.61471 25.543 6.71364 25.7113C6.81257 25.8796 6.95474 26.0183 7.12538 26.1131L16.9691 31.5818C17.1316 31.6721 17.3144 31.7195 17.5003 31.7195C17.6862 31.7195 17.869 31.6721 18.0314 31.5818L27.8752 26.1131C28.0458 26.0183 28.188 25.8796 28.2869 25.7113C28.3859 25.543 28.4379 25.3513 28.4378 25.1561V20.0141C28.4379 19.918 28.4128 19.8236 28.3648 19.7404C28.3169 19.6571 28.2479 19.588 28.1647 19.5399C28.0815 19.4918 27.9872 19.4664 27.8911 19.4664C27.795 19.4664 27.7007 19.4916 27.6175 19.5397L18.0431 25.0118C17.8778 25.1063 17.6907 25.156 17.5003 25.1561Z"
          fill="#BED9FF"
        />
        <path
          d="M33.9011 13.0223C33.9011 13.0223 33.9011 13.0168 33.9011 13.0148C33.8834 12.8414 33.8245 12.6748 33.7294 12.5287C33.6343 12.3827 33.5056 12.2615 33.3542 12.1753L18.0417 3.42531C17.8764 3.33083 17.6893 3.28113 17.4989 3.28113C17.3085 3.28113 17.1214 3.33083 16.9561 3.42531L1.64365 12.1753C1.47629 12.271 1.3372 12.4092 1.24046 12.5759C1.14372 12.7427 1.09277 12.932 1.09277 13.1248C1.09277 13.3176 1.14372 13.5069 1.24046 13.6737C1.3372 13.8404 1.47629 13.9786 1.64365 14.0743L16.9561 22.8243C17.1214 22.9188 17.3085 22.9685 17.4989 22.9685C17.6893 22.9685 17.8764 22.9188 18.0417 22.8243L31.514 15.1264C31.5348 15.1144 31.5584 15.108 31.5825 15.1081C31.6065 15.1081 31.6301 15.1145 31.6509 15.1265C31.6717 15.1386 31.689 15.1559 31.7009 15.1768C31.7129 15.1976 31.7191 15.2213 31.719 15.2453V25.1253C31.719 25.7139 32.1716 26.2191 32.7601 26.2485C32.908 26.2556 33.0558 26.2326 33.1946 26.1809C33.3333 26.1292 33.4601 26.0499 33.5673 25.9478C33.6745 25.8457 33.7598 25.7228 33.8181 25.5867C33.8764 25.4506 33.9065 25.3041 33.9065 25.1561V13.1248C33.9065 13.0906 33.9046 13.0563 33.9011 13.0223Z"
          fill="#BED9FF"
        />
      </>
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 35,
    viewBox: '0 0 35 35',
    width: 35
  })
)

export const StarIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    ),
    height: 32,
    viewBox: '0 0 512 512',
    width: 32
  })
)

export const StarOutlinedIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    ),
    height: 32,
    viewBox: '0 0 512 512',
    width: 32
  })
)

export const TwitterIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 512 512',
    width: 32
  })
)

export const UpRightFromSquareIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 18,
    viewBox: '0 0 512 512',
    width: 20
  })
)

export const VolumeIcon = withStyles(styles)(
  withSvg({
    children: (
      <>
        <path d="M232 416a23.88 23.88 0 01-14.2-4.68 8.27 8.27 0 01-.66-.51L125.76 336H56a24 24 0 01-24-24V200a24 24 0 0124-24h69.75l91.37-74.81a8.27 8.27 0 01.66-.51A24 24 0 01256 120v272a24 24 0 01-24 24zm-106.18-80zm-.27-159.86zM320 336a16 16 0 01-14.29-23.19c9.49-18.87 14.3-38 14.3-56.81 0-19.38-4.66-37.94-14.25-56.73a16 16 0 0128.5-14.54C346.19 208.12 352 231.44 352 256c0 23.86-6 47.81-17.7 71.19A16 16 0 01320 336z" />
        <path d="M368 384a16 16 0 01-13.86-24C373.05 327.09 384 299.51 384 256c0-44.17-10.93-71.56-29.82-103.94a16 16 0 0127.64-16.12C402.92 172.11 416 204.81 416 256c0 50.43-13.06 83.29-34.13 120a16 16 0 01-13.87 8z" />
        <path d="M416 432a16 16 0 01-13.39-24.74C429.85 365.47 448 323.76 448 256c0-66.5-18.18-108.62-45.49-151.39a16 16 0 1127-17.22C459.81 134.89 480 181.74 480 256c0 64.75-14.66 113.63-50.6 168.74A16 16 0 01416 432z" />
      </>
    ),
    height: 30,
    viewBox: '0 0 512 512',
    width: 34
  })
)

export const XIcon = withStyles(styles)(
  withSvg({
    children: (
      <path
        d="M15.8333 5.34163L14.6583 4.16663L9.99996 8.82496L5.34163 4.16663L4.16663 5.34163L8.82496 9.99996L4.16663 14.6583L5.34163 15.8333L9.99996 11.175L14.6583 15.8333L15.8333 14.6583L11.175 9.99996L15.8333 5.34163Z"
        fill="#252528"
      />
    ),
    height: 20,
    viewBox: '0 0 20 20',
    width: 20
  })
)

export const YouTubeIcon = withStyles(styles)(
  withSvg({
    children: (
      <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
    ),
    comment: FONT_AWESOME_DISCLAIMER,
    height: 32,
    viewBox: '0 0 576 512',
    width: 32
  })
)

withSvg.propTypes = {
  config: shape({
    children: element.isRequired,
    className: string,
    comment: string,
    height: number,
    viewBox: string.isRequired,
    width: number
  })
}

withSvg.defaultProps = {
  config: {
    className: null,
    comment: null,
    height: null,
    width: null
  }
}

HtmlComment.propTypes = {
  comment: string
}

HtmlComment.defaultProps = {
  comment: null
}
