import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './Notification.module.scss'

const Notification = ({ children, className }) => {
  const notificationClassNames = cx(styles.container, className)

  return (
    <div className={styles.root}>
      <div className={notificationClassNames}>
        <strong>{children}</strong>
      </div>
    </div>
  )
}

Notification.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string
}

Notification.defaultProp = {
  className: null
}

export default withStyles(styles)(Notification)
