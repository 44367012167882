import React from 'react'
import { object, string } from 'prop-types'
import { Dialog, DialogDismiss } from 'ariakit/dialog'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './Modal.module.scss'

const Modal = ({ children, className, heading, modalState }) => {
  const classNames = cx(styles.root, className)

  const isTestMode = process.env.NODE_ENV === 'test'

  return (
    <Dialog
      backdrop
      backdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.25)' } }}
      className={classNames}
      data-type="modal"
      portal={!isTestMode}
      state={modalState}
    >
      <header id="modal-header">
        <DialogDismiss className={styles.close}>
          <div className={styles.icon} />
          <span className={styles.back}>BACK</span>
        </DialogDismiss>
        {heading && <p className={styles.heading}>{heading}</p>}
      </header>
      {children}
    </Dialog>
  )
}

Modal.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  heading: string,
  modalState: object.isRequired
}

Modal.defaultProps = {
  className: null,
  heading: null
}

export default withStyles(styles)(Modal)
