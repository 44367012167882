import {
  DICTIONARY_SITENAME,
  DICTIONARY_URL,
  DICTIONARY_URL_CONTACT,
  PATHNAME_ACCOUNT,
  PATHNAME_CROSSWORD,
  PATHNAME_GAMES_WORD_PUZZLE,
  PATHNAME_WORD_LISTS,
  PLAY_DICTIONARY_URL,
  THESAURUS_URL,
  THESAURUS_URL_SOTD
} from '~client/constants'

const siteMenuData = dictionaryUrl => [
  {
    linkName: 'games',
    name: 'Games',
    subMenu: [
      {
        linkName: 'daily-crossword',
        name: 'Daily Crossword',
        url: `${dictionaryUrl}${PATHNAME_CROSSWORD}/`
      },
      {
        linkName: 'word-puzzle',
        name: 'Word Puzzle',
        url: `${dictionaryUrl}${PATHNAME_GAMES_WORD_PUZZLE}/`
      },
      {
        linkName: 'word-finder',
        name: 'Word Finder',
        url: `${DICTIONARY_URL}/e/word-finder/`
      },
      {
        linkName: 'all-games',
        name: 'All games',
        url: PLAY_DICTIONARY_URL
      }
    ],
    url: PLAY_DICTIONARY_URL
  },
  {
    linkName: 'featured',
    name: 'Featured',
    subMenu: [
      {
        linkName: 'word-of-the-day',
        name: 'Word of the Day',
        url: `${DICTIONARY_URL}/e/word-of-the-day/`
      },
      {
        linkName: 'synonym-of-the-day',
        name: 'Synonym of the Day',
        url: THESAURUS_URL_SOTD
      },
      {
        linkName: 'word-of-the-year',
        name: 'Word of the Year',
        url: `${DICTIONARY_URL}/e/word-of-the-year-2024/`
      },
      {
        linkName: 'new-words',
        name: 'New words',
        url: `${DICTIONARY_URL}/e/c/new-words/`
      },
      {
        linkName: 'language-stories',
        name: 'Language stories',
        url: `${DICTIONARY_URL}/e/c/language-stories/`
      },
      {
        linkName: 'all-featured',
        name: 'All featured',
        url: `${DICTIONARY_URL}/e/`
      }
    ],
    url: `${DICTIONARY_URL}/e/`
  },
  {
    linkName: 'pop-culture',
    name: 'Pop culture',
    subMenu: [
      {
        linkName: 'slang',
        name: 'Slang',
        url: `${DICTIONARY_URL}/e/slang/`
      },
      {
        linkName: 'emoji',
        name: 'Emoji',
        url: `${DICTIONARY_URL}/e/emoji/`
      },
      {
        linkName: 'memes',
        name: 'Memes',
        url: `${DICTIONARY_URL}/e/memes/`
      },
      {
        linkName: 'acronyms',
        name: 'Acronyms',
        url: `${DICTIONARY_URL}/e/acronyms/`
      },
      {
        linkName: 'gender-sexuality',
        name: 'Gender and sexuality',
        url: `${DICTIONARY_URL}/e/gender-sexuality/`
      },
      {
        linkName: 'all-pop-culture',
        name: 'All pop culture',
        url: `${DICTIONARY_URL}/e/all-the-words/`
      }
    ],
    url: `${DICTIONARY_URL}/e/all-the-words/`
  },
  {
    linkName: 'writing-tips',
    name: 'Writing tips',
    subMenu: [
      {
        linkName: 'writing-hub',
        name: 'Writing hub',
        url: `${THESAURUS_URL}/e/writing/`
      },
      {
        linkName: 'grammar-essentials',
        name: 'Grammar essentials',
        url: `${THESAURUS_URL}/e/grammar/`
      },
      {
        linkName: 'commonly-confused',
        name: 'Commonly confused',
        url: `${DICTIONARY_URL}/e/c/language-stories/commonly-confused/`
      },
      {
        linkName: 'all-writing-tips',
        name: 'All writing tips',
        url: `${THESAURUS_URL}/e/`
      }
    ],
    url: `${THESAURUS_URL}/e/`
  }
]

const accountSubmenuData = (dictionaryUrl, selectedSite, thesaurusUrl) => {
  const siteUrl =
    selectedSite === DICTIONARY_SITENAME ? dictionaryUrl : thesaurusUrl
  return [
    {
      linkName: 'account-settings',
      name: 'Account settings',
      url: `${dictionaryUrl}${PATHNAME_ACCOUNT}`
    },
    {
      linkName: 'my-word-lists',
      name: 'My word lists',
      url: `${dictionaryUrl}${PATHNAME_WORD_LISTS}`
    },
    {
      linkName: 'help-center',
      name: 'Help center',
      url: DICTIONARY_URL_CONTACT
    },
    {
      linkName: 'sign-out',
      name: 'Sign out',
      url: `${siteUrl}/auth?action=signout`
    }
  ]
}

export { accountSubmenuData, siteMenuData }
