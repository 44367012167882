import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './CheckBox.module.scss'

const CheckBox = ({ checked, label, name }) => {
  const [checkboxValue, setCheckboxValue] = useState(checked)

  const validateInput = e => {
    setCheckboxValue(!checkboxValue)
  }

  return (
    <div className={styles.root} data-type="checkbox">
      <input
        checked={checkboxValue}
        id={name}
        onChange={validateInput}
        type="checkbox"
      />
      <label htmlFor={name}>{label}</label>
    </div>
  )
}

CheckBox.propTypes = {
  checked: bool,
  label: string.isRequired,
  name: string.isRequired
}

CheckBox.defaultProps = {
  checked: false
}

export default withStyles(styles)(CheckBox)
