import React, { useId, useState } from 'react'
import { bool, node, object, oneOfType, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import A from '~elements/A/A'
import Button from '~elements/Button/Button'
import { PlusIcon } from '~elements/IconLibrary/IconLibrary'
import styles from './Accordion.module.scss'

const Accordion = ({
  children,
  className,
  defaultIsOpen,
  headline,
  headlineUrl,
  linkId,
  moduleLocation,
  ...props
}) => {
  const instanceId = useId()
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const classNames = cx(styles.root, className, {
    [styles.open]: isOpen
  })

  const onClick = () => setIsOpen(!isOpen)

  const clickLocation = moduleLocation && {
    ...moduleLocation,
    content_title: headline
  }

  const headlineElement = headlineUrl ? (
    <A clickLocation={clickLocation} href={headlineUrl} linkId={linkId}>
      {headline}
    </A>
  ) : (
    headline
  )

  const toggleClassNames = cx({
    [styles['open-toggle']]: isOpen
  })

  return (
    <div
      className={classNames}
      data-type="accordion"
      id={`accordion-${instanceId}`}
      {...props}
    >
      <div className={styles['title-container']}>
        <Button
          aria-controls={`panel-${instanceId}`}
          className={styles.toggle}
          id={`button-${instanceId}`}
          onClick={onClick}
        >
          <h2 id={`header-${instanceId}`}>{headlineElement}</h2>
          <PlusIcon className={toggleClassNames} />
        </Button>
      </div>
      <div
        aria-expanded={isOpen}
        aria-labelledby={`header-${instanceId}`}
        className={styles['content-container']}
        id={`panel-${instanceId}`}
      >
        {children}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  defaultIsOpen: bool,
  headline: oneOfType([string, node]).isRequired,
  headlineUrl: string,
  linkId: string,
  moduleLocation: object
}

Accordion.defaultProps = {
  className: null,
  defaultIsOpen: true,
  headlineUrl: null,
  linkId: null,
  moduleLocation: null
}

export default withStyles(styles)(Accordion)
