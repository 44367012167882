import { createSlice } from '@reduxjs/toolkit'
import {
  fetchDictionaryResults,
  fetchThesaurusCompareSynonyms,
  fetchThesaurusRelatedWords,
  fetchThesaurusResults,
  fetchDictionaryBrowseByLetterResults,
  fetchThesaurusBrowseByLetterResults
} from './actions'
import initialState from './state.json'

export const BROWSE_RESULTS_PER_PAGE = 300;

export const lexigraphSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchDictionaryResults.pending, state => {
        state.dictionaryData.error = null
        state.dictionaryData.status = 'loading'
      })
      .addCase(fetchDictionaryResults.fulfilled, (state, action) => {
        state.dictionaryData = {
          data: action.payload,
          error: null,
          status: 'success'
        }
      })
      .addCase(fetchDictionaryResults.rejected, (state, action) => {
        state.dictionaryData = {
          data: null,
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchThesaurusCompareSynonyms.pending, state => {
        state.thesaurusCompareSynonymsData.error = null
        state.thesaurusCompareSynonymsData.status = 'loading'
      })
      .addCase(fetchThesaurusCompareSynonyms.fulfilled, (state, action) => {
        state.thesaurusCompareSynonymsData = {
          data: action.payload,
          error: null,
          status: 'success'
        }
      })
      .addCase(fetchThesaurusCompareSynonyms.rejected, (state, action) => {
        state.thesaurusCompareSynonymsData = {
          data: null,
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchThesaurusRelatedWords.pending, state => {
        state.thesaurusRelatedWordsData.error = null
        state.thesaurusRelatedWordsData.status = 'loading'
      })
      .addCase(fetchThesaurusRelatedWords.fulfilled, (state, action) => {
        state.thesaurusRelatedWordsData = {
          data: action.payload,
          error: null,
          status: 'success'
        }
      })
      .addCase(fetchThesaurusRelatedWords.rejected, (state, action) => {
        state.thesaurusRelatedWordsData = {
          data: null,
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchThesaurusResults.pending, state => {
        state.thesaurusData.error = null
        state.thesaurusData.status = 'loading'
      })
      .addCase(fetchThesaurusResults.fulfilled, (state, action) => {
        state.thesaurusData = {
          data: action.payload,
          error: null,
          status: 'success'
        }
      })
      .addCase(fetchThesaurusResults.rejected, (state, action) => {
        state.thesaurusData = {
          data: null,
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchDictionaryBrowseByLetterResults.pending, state => {
        state.dictionaryBrowseByLetterData = {
          data: null,
          error: null,
          status: 'loading'
        };
      })
      .addCase(fetchDictionaryBrowseByLetterResults.fulfilled, (state, action) => {
        state.dictionaryBrowseByLetterData = {
          data: action.payload,
          error: null,
          status: 'success'
        };
      })
      .addCase(fetchDictionaryBrowseByLetterResults.rejected, (state, action) => {
        state.dictionaryBrowseByLetterData = {
          data: null,
          error: action.error.message,
          status: 'failed'
        };
      })
      .addCase(fetchThesaurusBrowseByLetterResults.pending, state => {
        state.thesaurusBrowseByLetterData = {
          data: null,
          error: null,
          status: 'loading'
        };
      })
      .addCase(fetchThesaurusBrowseByLetterResults.fulfilled, (state, action) => {
        state.thesaurusBrowseByLetterData = {
          data: action.payload,
          error: null,
          status: 'success'
        };
      })
      .addCase(fetchThesaurusBrowseByLetterResults.rejected, (state, action) => {
        state.thesaurusBrowseByLetterData = {
          data: null,
          error: action.error.message,
          status: 'failed'
        };
      });
  },
  initialState,
  name: 'lexigraph',
  reducers: {
    resetLexigraphSlice: () => initialState
  }
})

export const actions = lexigraphSlice.actions

export default lexigraphSlice.reducer
