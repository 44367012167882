// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S52C7zHpk0k8mwn5Ce7w>section{height:0;overflow:hidden}.S52C7zHpk0k8mwn5Ce7w>button{background-color:rgba(0,0,0,0);border:none;padding:0;text-transform:uppercase;width:100%}.S52C7zHpk0k8mwn5Ce7w>button:hover{color:var(--warmgray)}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/ExpandableContent/ExpandableContent.module.scss"],"names":[],"mappings":"AACE,8BACE,QAAA,CACA,eAAA,CAGF,6BACE,8BAAA,CACA,WAAA,CACA,SAAA,CACA,wBAAA,CACA,UAAA,CAEA,mCACE,qBAAA","sourcesContent":[".root {\n  & > section {\n    height: 0;\n    overflow: hidden;\n  }\n\n  & > button {\n    background-color: transparent;\n    border: none;\n    padding: 0;\n    text-transform: uppercase;\n    width: 100%;\n\n    &:hover {\n      color: var(--warmgray);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `S52C7zHpk0k8mwn5Ce7w`
};
module.exports = ___CSS_LOADER_EXPORT___;
