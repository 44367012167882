import React from 'react'
import { arrayOf, node, oneOfType, string } from 'prop-types'
import {
  Tooltip as AriaTooltip,
  TooltipAnchor,
  useTooltipStore
} from '@ariakit/react'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './Tooltip.module.scss'

const Tooltip = ({ children, className, content }) => {
  const classNames = cx(styles.root, className)
  const tooltipStore = useTooltipStore()

  return (
    <>
      <TooltipAnchor store={tooltipStore}>{children}</TooltipAnchor>
      <AriaTooltip className={classNames} portal={false} store={tooltipStore}>
        {content}
      </AriaTooltip>
    </>
  )
}

Tooltip.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  content: oneOfType([arrayOf(node), node, string]).isRequired
}

Tooltip.defaultProps = {
  className: null
}

export default withStyles(styles)(Tooltip)
