/**
 * This file contains the linkids used for the "data-linkid" property of clickable items
 *
 * Values gathered from https://iacpublishing.atlassian.net/wiki/spaces/DAISY/pages/6946990/Thesaurus.com+Mobile+HP and https://iacpublishing.atlassian.net/wiki/spaces/DAISY/pages/4528348/Dictionary.com+Desktop+Homepage */

module.exports = {
  INNER_TARGET_RECOVER_PASSWORD: {
    getLink: 'ua_pwd-recover-get-link',
    resendLink: 'ua_pwd-resend-recovery-link'
  },
  INNER_TARGET_UPDATE_PASSWORD: {
    update: 'ua_update-password'
  },
  LINKID_BROWSE_AZ: '74qg7q',
  LINKID_LEARNING_HUB_TABS: {
    quizzes: '7yvffo',
    wordLists: '3yyvtd'
  },
  LINKID_NEW_WORDS_BANNER: {
    button: 'rxnauy',
    image: 'qi6x0n'
  },
  LINKID_PUBLISHERS: {
    dailyBeast: 'fboxke',
    gutenberg: 'sl8bq9',
    nlp: '98q6tt'
  },
  LINKID_QUIZ: {
    GO_TO_QUIZ: 'oxcbq4',
    LANDING_PAGE_RECIRCULATED: '1r44rs'
  },
  LINKID_SIGNIN: {
    continue: '7osns1',
    signup: '3avlvd'
  },
  LINKID_SIGNUP: {
    continue: 'ilv7bm',
    signin: 'lw5h2s'
  },
  LINKID_SOTD: {
    cta: 'xwee84',
    deflector: 'lpcqde',
    question: '17vwmm'
  },
  LINKID_SYNONYM_COMPARISON: {
    antonym: 'psd2ic',
    compareModalCTA: 'ppxhix',
    firstCrumb: 'se362z',
    secondCrumb: '89qn80',
    synonym: 'y2woe7'
  },
  LINKID_WORD_COMPARISON: {
    backButton: 'ckqqj2',
    headword: 'lyfbz3',
    keyphrase: 'dgyvft'
  },
  LINKID_WOTD: 'nx1fkx',
  LINKID_WOTD_MEANINGS_EXAMPLES: '3s1dgz'
}
