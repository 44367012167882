import { createAsyncThunk } from '@reduxjs/toolkit'
import 'isomorphic-unfetch'

export const fetchPageData = createAsyncThunk('fetchPageData', async () => {
  const response = await fetch(`${process.env.SAFE_CONTENT_URL}/homepage.json`)
    .then(data => data.json())
    .catch(err => {
      throw err
    })

  return response
})
