import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_SITENAME,
  DICTIONARY_URL,
  PROPTYPE_SITENAME,
  THESAURUS_SITENAME,
  THESAURUS_URL
} from '~client/constants'
import { A, DictionaryLogo, ThesaurusLogo } from '~elements'
import styles from './SitesMenu.module.scss'

export const SitesMenu = ({
  className,
  dictionaryUrl,
  selectedSite,
  thesaurusUrl
}) => {
  const classNames = cx(styles.root, className)

  return (
    <nav
      aria-label="Switch Site"
      className={classNames}
      data-type="sites-menu"
      role="tablist"
    >
      <A
        aria-selected={selectedSite === DICTIONARY_SITENAME}
        className={styles['navigation-tab']}
        data-analytics="aa-track"
        data-linkmodule="site-navigation"
        data-linkmodule_position={1}
        data-linkname="dictionary.com"
        data-linktype="cta"
        data-linkurl={dictionaryUrl}
        data-location="header"
        data-type="sites-menu"
        href={dictionaryUrl}
        id="dictionary-tab"
        role="tab"
      >
        <div className={styles['navigation-tab-logo']}>
          <DictionaryLogo />
        </div>
      </A>
      <A
        aria-selected={selectedSite === THESAURUS_SITENAME}
        className={styles['navigation-tab']}
        data-analytics="aa-track"
        data-linkmodule="site-navigation"
        data-linkmodule_position={2}
        data-linkname="thesaurus.com"
        data-linktype="cta"
        data-linkurl={thesaurusUrl}
        data-location="header"
        data-type="sites-menu"
        href={thesaurusUrl}
        id="thesaurus-tab"
        role="tab"
      >
        <div className={styles['navigation-tab-logo']}>
          <ThesaurusLogo />
        </div>
      </A>
    </nav>
  )
}

SitesMenu.propTypes = {
  className: string,
  dictionaryUrl: string,
  selectedSite: PROPTYPE_SITENAME.isRequired,
  thesaurusUrl: string
}

SitesMenu.defaultProps = {
  className: null,
  dictionaryUrl: DICTIONARY_URL,
  thesaurusUrl: THESAURUS_URL
}

export default withStyles(styles)(SitesMenu)
