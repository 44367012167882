// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aDge7dw61wLAE7rfvp9e{color:#333;display:inline-block;font-family:Helvetica-Light,sans-serif;line-height:32px;margin-left:8px;margin-right:8px;text-align:center;text-transform:uppercase;user-select:none}.aDge7dw61wLAE7rfvp9e:first-of-type{margin-left:16px}.aDge7dw61wLAE7rfvp9e:last-of-type{margin-right:16px}.aDge7dw61wLAE7rfvp9e a{color:var(--lightgray);text-decoration:none}.aDge7dw61wLAE7rfvp9e span{color:#ccc;cursor:default}.PYrLZOqq1y1ev12ox3JM span:hover{cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/PagingArrow/PagingArrow.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,oBAAA,CACA,sCAAA,CACA,gBAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,wBAAA,CACA,gBAAA,CAEA,oCACE,gBAAA,CAGF,mCACE,iBAAA,CAGF,wBACE,sBAAA,CACA,oBAAA,CAGF,2BACE,UAAA,CACA,cAAA,CAKF,iCACE,kBAAA","sourcesContent":[".root {\n  color: #333;\n  display: inline-block;\n  font-family: Helvetica-Light, sans-serif;\n  line-height: 32px;\n  margin-left: 8px;\n  margin-right: 8px;\n  text-align: center;\n  text-transform: uppercase;\n  user-select: none;\n\n  &:first-of-type {\n    margin-left: 16px;\n  }\n\n  &:last-of-type {\n    margin-right: 16px;\n  }\n\n  a {\n    color: var(--lightgray);\n    text-decoration: none;\n  }\n\n  span {\n    color: #ccc;\n    cursor: default;\n  }\n}\n\n.not-allowed {\n  span:hover {\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `aDge7dw61wLAE7rfvp9e`,
	"not-allowed": `PYrLZOqq1y1ev12ox3JM`
};
module.exports = ___CSS_LOADER_EXPORT___;
