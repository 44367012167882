import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './LoadingDots.module.scss'

export const LoadingDots = ({ className }) => {
  const classNames = cx(styles.root, className)

  const loadingDot = <circle cy="50%" r="4px" />

  return (
    <svg
      className={classNames}
      data-type="loading-dots"
      viewBox="0 0 200 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      {loadingDot}
      {loadingDot}
      {loadingDot}
    </svg>
  )
}

LoadingDots.propTypes = {
  className: string
}

LoadingDots.defaultProps = {
  className: null
}

export default withStyles(styles)(LoadingDots)
