import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import A from '~elements/A/A'
import styles from './DefaultLink.module.scss'

const DefaultLink = ({ children, className, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <A className={classNames} {...props}>
      {children}
    </A>
  )
}

DefaultLink.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  href: string.isRequired
}

DefaultLink.defaultProps = {
  className: null
}

export default withStyles(styles)(DefaultLink)
