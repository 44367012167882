import parse, { attributesToProps, domToReact } from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import A from '~elements/A/A'

const options = {
  replace: ({ name, attribs, children }) => {
    const props = attributesToProps(attribs)

    switch (name) {
      case 'a':
        return (
          <A
            clickLocation={props['data-location']}
            linkId={props['data-linkid']}
            {...props}
          >
            {domToReact(children, options)}
          </A>
        )
      case 'script':
      case 'form':
        return <></>
    }
  }
}

const parseHtml = (html, overrides = {}) => {
  if (typeof html !== 'string') return

  const config = {
    sanitize: true
  }

  Object.assign(config, overrides)

  let sanitized

  if (config.sanitize) {
    sanitized = sanitizeHtml(html, {
      allowVulnerableTags: true,
      allowedAttributes: false,
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'audio',
        'form',
        'source',
        'script'
      ]),
      disallowedTagsMode: 'recursiveEscape'
    })
  }

  const output = config.sanitize ? sanitized : html

  return parse(output, options)
}

export const emphasizeAndParse = (text, word) => {
  const escapedWord = word.replace(/[-/\\^$*+?.()|[\]{}p]/g, '\\$&')
  const pattern = new RegExp(`(\\b)${escapedWord}(\\b)`, 'gmi')
  const italicizedText = text.replace(pattern, `<em>${word}</em>`)

  return parse(italicizedText, options)
}

export default parseHtml
