export const capitalize = word =>
  word && `${word[0].toUpperCase()}${word.slice(1)}`

export const escapeRegExpString = string =>
  string.replace(/[[\]*+?{}.()^$|\\-]/g, '\\$&')

export const toKebabCase = text =>
  text
    .replace(/[\W_]+/gi, '-')
    .replace(/\W$/, '')
    .toLowerCase()

export const toSlugCase = text => {
  if (!text) return text
  return text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[^\w']+/gi, '-')
    .replace(/\W$/, '')
    .toLowerCase()
}

export const truncate = (sentence, maxLength) =>
  sentence.length > maxLength
    ? `${sentence.substring(0, maxLength)}...`
    : sentence
