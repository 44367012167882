import { enc, SHA256 } from 'crypto-js'
import { print } from 'graphql'
import fetch from 'isomorphic-unfetch'

const calculateSha256Hash = query => SHA256(query).toString(enc.Hex)
const removeExtraWhitespace = text => text.replace(/\s+/g, ' ').trim()

const getCachedQueryResults = async ({ endpoint, query, variables }) => {
  const queryString = removeExtraWhitespace(print(query))
  const sha256Hash = calculateSha256Hash(queryString)
  const variablesString = JSON.stringify(variables)

  const extensions = JSON.stringify({
    persistedQuery: { sha256Hash, version: 1 }
  })

  const paramsWithoutQuery = new URLSearchParams({
    extensions,
    variables: variablesString
  })

  const paramsWithQuery = new URLSearchParams({
    extensions,
    query: queryString,
    variables: variablesString
  })

  const headers = { 'Content-Type': 'application/json' }

  const initialResponse = await fetch(`${endpoint}?${paramsWithoutQuery}`, {
    headers
  }).then(data => data.json())

  if (initialResponse.errors) {
    const response = await fetch(`${endpoint}?${paramsWithQuery}`, {
      headers
    })
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    if (response.errors) {
      // eslint-disable-next-line no-console
      console.error(response)
    }

    return response.data
  } else {
    return initialResponse.data
  }
}

export default getCachedQueryResults
