import { createSelector } from '@reduxjs/toolkit'
import { selectLocationPathname } from '~store/location/selectors'
import isArrayWithLength from '~utils/isArrayWithLength'

const EMPTY_OBJECT = {}

export const selectPageContent = state => state?.page?.content

export const selectCommonQuizModuleData = createSelector(
  selectPageContent,
  pageContent => pageContent?.quizModule?.[0] || EMPTY_OBJECT
)

export const selectCommonQuizModuleArticle = createSelector(
  selectCommonQuizModuleData,
  commonQuizModuleData => commonQuizModuleData?.articleLink?.[0] || EMPTY_OBJECT
)

export const selectHeroModuleData = createSelector(
  selectPageContent,
  pageContent => pageContent?.heroModule?.[0] || EMPTY_OBJECT
)

export const selectHeroModuleCta = createSelector(
  selectHeroModuleData,
  heroModuleData => heroModuleData?.cta?.[0] || EMPTY_OBJECT
)

export const selectRecirculationModuleData = createSelector(
  selectPageContent,
  pageContent => pageContent?.recirculationModule?.[0] || EMPTY_OBJECT
)

export const selectTrendingModuleData = createSelector(
  selectPageContent,
  pageContent => pageContent?.trendingModule?.[0] || EMPTY_OBJECT
)

export const selectXotdCampaignData = createSelector(
  selectPageContent,
  pageContent => pageContent?.xotdModule?.[0] || EMPTY_OBJECT
)

export const selectRibbonData = createSelector(
  selectPageContent,
  selectLocationPathname,
  (pageContent, pathname) => {
    const isHomepage = pathname === '/'
    const { largeRibbonModule, ribbonModule } = pageContent || EMPTY_OBJECT

    if (isHomepage && isArrayWithLength(largeRibbonModule)) {
      return largeRibbonModule[0]
    } else if (isArrayWithLength(ribbonModule)) {
      return ribbonModule[0]
    }
  }
)
