import { DICTIONARY_SITENAME, THESAURUS_SITENAME } from '~client/constants'

const DEFAULT_SUBDOMAIN = 'www'
const FALLBACK_HOSTNAME = 'local.dictionary.com'

const getSiteContext = location => {
  const context = { ...location }
  const { hostname = FALLBACK_HOSTNAME, pathname, port, protocol } = location

  const portString = port ? `:${port}` : ''
  const hostParts = hostname.split('.')
  const hostPartsLength = hostParts.length
  const isValidHostname = hostPartsLength > 1
  const hasSubdomain = hostPartsLength > 2

  if (isValidHostname) {
    const subdomain = hasSubdomain
      ? hostParts.splice(0, hostPartsLength - 2).join('.')
      : DEFAULT_SUBDOMAIN
    const [siteName, tld] = hostParts

    context.name = siteName
    context.dictionaryUrl = `${protocol}//${subdomain}.${DICTIONARY_SITENAME}.${tld}${portString}`
    context.thesaurusUrl = `${protocol}//${subdomain}.${THESAURUS_SITENAME}.${tld}${portString}`
    context.subdomain = subdomain
    context.tld = tld
    context.url = `${protocol}//${hostname}${portString}${pathname}`
  }

  return context
}

export default getSiteContext
