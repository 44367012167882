import React from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Link } from 'react-router-dom'
import { PROPTYPE_CHILDREN } from '~client/constants'
import styles from './PagingArrow.module.scss'

export const PagingArrow = ({ children, className, href, notAllowed }) => {
  const classNames = cx(styles.root, className)
  const spanClassNames = cx({
    [styles['not-allowed']]: notAllowed
  })

  const content = href ? (
    <Link to={href}>{children}</Link>
  ) : (
    <span className={spanClassNames}>{children}</span>
  )

  return (
    <li className={classNames} data-type="paging-arrow">
      {content}
    </li>
  )
}

PagingArrow.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  href: string,
  notAllowed: bool
}

PagingArrow.defaultProps = {
  className: null,
  href: null,
  notAllowed: false
}

export default withStyles(styles)(PagingArrow)
